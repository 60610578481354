import { localStorageKeys } from "../utils/local-storage/local-storage-keys";
import { getLocalStorageItem } from "../utils/local-storage/local-storage";

const configKeys = {
  NUMBER_FORMAT: "number_format",
  TOAST_TIME: "toast_time",
  TAB_MAX_WIDTH: "tab_max_width",
  TAB_MIN_WIDTH: "tab_min_width",
  SESSION_REFRESH_TIME: "session_refresh_time",
  CHECK_SESSION_INTERVAL_TIME: "check_session_interval_time",
  DATAGRID_MAX_SELECT_COUNT: "datagrid_max_select_count",
};

const getConfig = () => getLocalStorageItem(localStorageKeys.APP_CONFIG);

const findConfig = (el, code) => el.code === code;

const findNumberFormatConfig = (el) => findConfig(el, configKeys.NUMBER_FORMAT);

const findToastTimeConfig = (el) => findConfig(el, configKeys.TOAST_TIME);

const findTabMaxWidthConfig = (el) => findConfig(el, configKeys.TAB_MAX_WIDTH);

const findTabMinWidthConfig = (el) => findConfig(el, configKeys.TAB_MIN_WIDTH);

const findSessionRefreshTimeConfig = (el) =>
  findConfig(el, configKeys.SESSION_REFRESH_TIME);

const findCheckSessionIntervalTime = (el) =>
  findConfig(el, configKeys.CHECK_SESSION_INTERVAL_TIME);

const findDatagridMaxSelectCount = (el) =>
  findConfig(el, configKeys.DATAGRID_MAX_SELECT_COUNT);

export const getNumberFormatConfig = () =>
  getConfig()?.find(findNumberFormatConfig)?.value;

export const getToastTimeConfig = () =>
  getConfig()?.find(findToastTimeConfig)?.value;

export const getTabMaxWidthConfig = () =>
  getConfig()?.find(findTabMaxWidthConfig)?.value;

export const getTabMinWidthConfig = () =>
  getConfig()?.find(findTabMinWidthConfig)?.value;

export const getSessionRefreshTimeConfig = () =>
  getConfig()?.find(findSessionRefreshTimeConfig)?.value;

export const getCheckSessionIntervalTime = () =>
  getConfig()?.find(findCheckSessionIntervalTime)?.value;

export const getDatagridMaxSelectCount = () =>
  getConfig()?.find(findDatagridMaxSelectCount)?.value;
