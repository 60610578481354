// @flow
import React from "react";
import type { Column } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { EditCell } from "./edit-cell";
import { DisplayCell } from "./display-cell";
import { Box } from "@chakra-ui/react";
import { SummaryCell } from "./summary-cell";
import { Cell } from "./cell";

type FooterCellProps = {
  column: Column,
  value: string | Date | number,
  row: any,
  onClick: () => void,
};

export const FooterCell = (props: FooterCellProps) => {
  const { summaryOptions } = useDatagridContext();
  const { column } = props;

  return summaryOptions?.columns &&
    summaryOptions?.columns?.length > 0 &&
    summaryOptions?.columns?.includes(column.name) ? (
    <SummaryCell column={column} width={column.width} />
  ) : (
    <Cell column={column} width={column.width} />
  );
};
