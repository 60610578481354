import { uuidv4 } from "@echo/tools";
import { saveLog, saveLogRange, getLogSeverity } from "../services/log-service";

export const LOG_SEVERITY = {
  ERROR: 1,
  INFO: 2,
  EVENT: 4,
  DEBUG: 8,
};

export const LOG_LOCATION = {
  BACKEND: 1,
  FRONTEND: 2,
};

export const OPERATION_TYPE = {
  OTHER: 0,
  ERROR: 1,
  BLOCK_EXECUTE: 2,
  PROCESS_BLOCK_EXECUTE: 4,
  API_CALL: 8,
};

const maxLogQueueSize = 200;
const enableBatching = true;

let logQueue = [];

let logSeverity = null;

const getSeverity = async () => {
  if (!logSeverity) {
    try {
      const logSeverityString = await getLogSeverity();
      logSeverity =
        LOG_SEVERITY[logSeverityString] || LOG_SEVERITY[LOG_SEVERITY.ERROR];
    } catch {
      logSeverity = LOG_SEVERITY.ERROR;
    }
  }

  return logSeverity;
};

const sync = async () => {
  if (logQueue.length && logQueue.length >= maxLogQueueSize) {
    const toSave = logQueue.splice(0, 200);
    await saveLogRange(toSave.map((l) => l.log));

    // TODO In the future implement config sync here
  }
};

/**
 * @callback LogFunc
 * @param {string} source Source
 * @param {string} message Log message
 * @param {Object} metadata Metadata. Eg. userAgent
 * @param {keyof OPERATION_TYPE} operationType
 * @param {number} identity Identity of related entity
 * @param {string} startDate Start date
 * @param {string} endDate End date
 */

/**
 * @callback GeneralLogFunc
 * @param {string} source Source
 * @param {string} message Log message
 * @param {Object} metadata Metadata. Eg. userAgent
 * @param {keyof LOG_SEVERITY} severity
 * @param {keyof LOG_LOCATION} location
 * @param {keyof OPERATION_TYPE} operationType
 * @param {number} identity Identity of related entity
 * @param {string} startDate Start date
 * @param {string} endDate End date
 */

/**
 * @typedef {Object} Logger
 * @property {GeneralLogFunc} log
 * @property {LogFunc} logError
 * @property {LogFunc} logInfo
 * @property {LogFunc} logDebug
 * @property {LogFunc} logEvent
 */

/**
 * Create logger
 * @returns {Logger} Returns logger object
 */
export const createLogger = () => {
  const log = async (
    source,
    message,
    metadata,
    severity,
    operationType,
    identity = null,
    startDate = null,
    endDate = null,
  ) => {
    if ((await getSeverity()) < severity) {
      return null;
    }
    const correlationId = uuidv4();
    const logMsg = {
      source,
      message,
      metadata: JSON.stringify(metadata),
      severity,
      correlationId,
      location: LOG_LOCATION.FRONTEND,
      operationType,
      identity,
      startDate,
      endDate,
    };

    try {
      if (enableBatching) {
        logQueue.push({ date: new Date(), log: logMsg });

        await sync();
      } else {
        await saveLog(logMsg);
      }
    } catch (e) {
      console.error("Cannot save log message. Please check error below");
      console.error(e);
    }

    return correlationId;
  };

  const logError = async (
    source,
    message,
    metadata,
    operationType,
    identity,
    startDate = null,
    endDate = null,
  ) =>
    await log(
      source,
      message,
      metadata,
      LOG_SEVERITY.ERROR,
      operationType || OPERATION_TYPE.ERROR,
      identity,
      startDate,
      endDate,
    );

  const logInfo = async (
    source,
    message,
    metadata,
    operationType,
    identity,
    startDate = null,
    endDate = null,
  ) =>
    await log(
      source,
      message,
      metadata,
      LOG_SEVERITY.INFO,
      operationType,
      identity,
      startDate,
      endDate,
    );

  const logEvent = async (
    source,
    message,
    metadata,
    operationType,
    identity,
    startDate = null,
    endDate = null,
  ) =>
    await log(
      source,
      message,
      metadata,
      LOG_SEVERITY.EVENT,
      operationType,
      identity,
      startDate,
      endDate,
    );

  const logDebug = async (
    source,
    message,
    metadata,
    operationType,
    identity,
    startDate = null,
    endDate = null,
  ) =>
    await log(
      source,
      message,
      metadata,
      LOG_SEVERITY.DEBUG,
      operationType,
      identity,
      startDate,
      endDate,
    );

  return {
    log,
    logError,
    logInfo,
    logDebug,
    logEvent,
  };
};
