import { Box, Text } from "@chakra-ui/react";
import { getLocalStorageItem } from "../../utils/local-storage/local-storage";
import { localStorageKeys } from "../../utils/local-storage/local-storage-keys";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

export const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  const [currentVersion, setCurrentVersion] = useState();

  useEffect(() => {
    let interval;
    if (!currentVersion) {
      const version = getLocalStorageItem(localStorageKeys.VERSION);

      if (version) {
        setCurrentVersion(version);
      } else {
        interval = setInterval(() => {
          const version = getLocalStorageItem(localStorageKeys.VERSION);
          if (version) {
            setCurrentVersion(version);
            clearInterval(interval);
          }
        }, 500);
      }
    }

    return () => clearInterval(interval);
  }, [currentVersion]);

  const infoList = useMemo(
    () => [
      {
        text: `Copyright 2021-${currentYear} @ Caldo Wszelkie prawa zastrzeżone`,
      },
      ...(currentVersion
        ? [
            {
              text: `v${currentVersion}`,
            },
          ]
        : []),
      {
        text: "Dział IT",
        style: { marginLeft: "auto" },
      },
    ],
    [currentVersion, currentYear],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      boxSizing="border-box"
      fontSize="0.8rem"
      width="100%"
      backgroundColor="#E8190C"
      alignItems="center"
      justifyContent="flex-start"
      color="#FFFFFF"
      padding="0px 8px"
      gap="10px"
    >
      {infoList.map((el, idx) => (
        <TextBox text={el.text} style={el.style} key={idx} />
      ))}
    </Box>
  );
};

const TextBox = ({ text, style }) => {
  return (
    <Box
      display="flex"
      alignSelf="center"
      justifyContent="center"
      style={style}
    >
      <Text>{text}</Text>
    </Box>
  );
};

TextBox.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
};
