import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { InputContextProvider } from "./context/input-context";
import { InputField } from "./components/input-field";
import { InputStepper } from "./components/input-stepper";

const InputNumeric = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    format,
    step,
    min,
    max,
    style,
    isRequired,
    isInvalid,
    isDisabled,
    isReadOnly,
    tabIndex,
    autoFocus,
    onBlur,
  } = props;

  return (
    <FormControl
      id={id}
      isRequired={isRequired}
      isInvalid={isInvalid}
      data-testid="number-input-wrapper-test"
      position="relative"
      padding="0"
      variant="floating"
      display="flex"
      alignItems="center"
    >
      <InputContextProvider
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        step={parseFloat(step)}
        format={format}
        min={min}
        max={max}
      >
        <Box
          overflow="hidden"
          position="relative"
          display="flex"
          alignItems="center"
        >
          <InputField
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            fontSize={style?.fontSize}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
          />
          <InputStepper
            isDisabled={isDisabled || isReadOnly}
            tabIndex={tabIndex}
          />
        </Box>
      </InputContextProvider>
      {label && <FormLabel>{label}</FormLabel>}
    </FormControl>
  );
};

InputNumeric.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  format: PropTypes.string,
  precision: PropTypes.number,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  variant: PropTypes.string,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isUnique: PropTypes.bool,
  style: PropTypes.object,
  rootProps: PropTypes.object,
  ref: PropTypes.any,
  tabIndex: PropTypes.string,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default InputNumeric;
