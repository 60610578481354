import { baseGet, basePost } from "./base-fetch/base-fetch";

export const getPropertyDefinitionById = (componentId) =>
  baseGet("ComponentPropertyDefinition/GetDefinitionById", { componentId });

export const saveComponentPropertyDefinition = (componentId, properties) =>
  basePost("ComponentPropertyDefinition/SaveDefinition", properties, {
    componentId,
  });

export const getPropertyDefinitionTypes = () =>
  baseGet("ComponentPropertyDefinition/GetPropertyTypes");
