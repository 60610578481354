import { Box, Button } from "@chakra-ui/react";
import { DatagridV2 } from "@echo/datagrid";
import { uuidv4 } from "@echo/tools";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { getId } from "../../../../shared/echo-components/base-components/components/datagrid-v2-component/utils/get-id";
import { getColumns } from "./utils/column-utils";

export const PermissionsTab = ({ model, updateModel }) => {
  const [enableAdding, setEnableAdding] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleAdd = () => setEnableAdding((prev) => !prev);

  const generateId = (obj) => ({ ...obj, id: uuidv4() });

  const onRowAdd = useCallback(
    (permission) => {
      updateModel({
        ...model,
        permissions: [...(model.permissions || []), generateId(permission)],
      });
      setEnableAdding(false);
    },
    [model, updateModel],
  );

  const getNewPermissions = (arr, element) =>
    arr.map((el) => (getId(el) === getId(element) ? element : el));

  const onRowEdit = useCallback(
    (permission) => {
      updateModel({
        ...model,
        permissions: [...getNewPermissions(model.permissions, permission)],
      });
    },
    [model, updateModel],
  );

  const handleDeleteSelected = useCallback(() => {
    updateModel({
      ...model,
      permissions: [
        ...model.permissions.filter(
          (p) => !selectedItems.includes(getId(p).toString()),
        ),
      ],
    });
    setSelectedItems([]);
  }, [model, updateModel, selectedItems]);

  const actions = {
    onRowAdd,
    onRowEdit,
    onRowSelect: (id, type) => {
      if (type === "select") {
        setSelectedItems((prev) => [...prev, id.toString()]);
      }
      if (type === "unselect") {
        setSelectedItems((prev) => [
          ...prev.filter((idx) => idx.toString() !== id.toString()),
        ]);
      }
    },
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box display="flex" alignItems="center" gap="10px">
        <Button onClick={toggleAdd}>Add permission</Button>
        <Button onClick={handleDeleteSelected}>Delete selected</Button>
      </Box>
      <Box>
        <DatagridV2
          data={model.permissions || []}
          selectedItems={selectedItems}
          itemsCount={(model.permissions || []).length}
          columns={getColumns(model.permissions)}
          selectionMode={"multiple"}
          actions={actions}
          enableAdding={enableAdding}
          enableEdit
        />
      </Box>
    </Box>
  );
};

PermissionsTab.propTypes = {
  model: PropTypes.object,
  updateModel: PropTypes.func,
};
