import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import PropertiesTable from "../../../../../shared/properties/properties-table/properties-table";
import ProcessGroupSelect from "../../../../../shared/properties/properties-table/cell-components/process-group-select";
import EchoStateValueCell from "../../../../../shared/properties/component-properties-table/echo-state-value-cell";
import { ComponentPropertiesDesignerPropEdit } from "../../../../../shared/properties/component-properties-designer";
import { useEffect, useState } from "react";
import { getAvailableObjects } from "../../../../../../services/echo-object-service";
import { showErrorToast } from "../../../../../shared/echo-error-toast";

const getPropertiesTableSchema = (availableObjects) => [
  {
    propName: "type",
    propType: "combo",
    options: [
      { value: "Component", label: "Component" },
      { value: "Container", label: "Container" },
      { value: "Form", label: "Form" },
      { value: "Template", label: "Template" },
    ],
  },
  { propName: "name", propType: "input" },
  { propName: "description", propType: "input" },
  {
    propName: "relatedObjectId",
    propType: "combo",
    options: availableObjects
      .map((obj) => ({
        value: obj.id,
        label: obj.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    disabled: (model) => model.type === "Template",
  },
  {
    propName: "inParameters",
    propType: "input",
    disabled: (model) => model.type === "Template",
  },
  {
    propName: "tabLabelFormat",
    propType: "input",
    disabled: (model) => model.type === "Template",
  },
  {
    propName: "menuElementCondition",
    propType: "input",
    disabled: (model) => model.type === "Template",
  },
  {
    propName: "menuElementName",
    propType: "input",
    disabled: (model) => !model["menuElementCondition"],
  },
  {
    propName: "menuElementPath",
    propType: "input",
    disabled: (model) => !model["menuElementCondition"],
  },
  {
    propName: "properties",
    propType: "properties",
    disabled: (model) => model.type === "Template",
  },
  {
    propName: "crudActions",
    propType: "processGroupSelect",
    subOptions: [
      {
        propName: "crudCreate",
        propType: "processSelect",
        disabled: (model) => model.type === "Template",
      },
      {
        propName: "crudRead",
        propType: "processSelect",
        disabled: (model) => model.type === "Template",
      },
      {
        propName: "crudUpdate",
        propType: "processSelect",
        disabled: (model) => model.type === "Template",
      },
      {
        propName: "crudDelete",
        propType: "processSelect",
        disabled: (model) => model.type === "Template",
      },
    ],
    disabled: (model) => model.type === "Template",
  },
];

const customTypes = [
  {
    name: "processGroupSelect",
    component: ({ propValue, onChange }) => (
      <ProcessGroupSelect
        propValue={propValue}
        onChange={onChange}
        tag="CRUD"
      />
    ),
  },
  {
    name: "processSelect",
    component: ({ propValue, onChange, propName }) => (
      <EchoStateValueCell
        propName={propName}
        propValue={propValue}
        onChange={onChange}
      />
    ),
  },
  {
    name: "properties",
    component: ({ propValue, onChange, propName, model }) => (
      <ComponentPropertiesDesignerPropEdit
        propName={propName}
        propValue={propValue}
        onChange={onChange}
        component={model}
      />
    ),
  },
];

const DesigneeProperties = ({
  model = {},
  onModelChange = shimFunctions.shimFunction1,
  style = { padding: "20px 15px" },
  title = "Components Properties",
}) => {
  const [availableRelatedObjects, setAvailableRelatedObjects] = useState([]);

  const handleOnModelChange = (newModel) => {
    const oldRelatedObject = availableRelatedObjects.find(
      // eslint-disable-next-line eqeqeq
      (obj) => obj.id == model.relatedObjectId,
    );
    const newRelatedObject = availableRelatedObjects.find(
      // eslint-disable-next-line eqeqeq
      (obj) => obj.id == newModel.relatedObjectId,
    );

    if (
      (!oldRelatedObject && newRelatedObject) ||
      (oldRelatedObject &&
        newRelatedObject &&
        oldRelatedObject?.name !== newRelatedObject?.name)
    ) {
      const crudActions = {
        id: 1,
        name: "MsSQL form CRUD",
        staticParams: {
          databaseName: newRelatedObject?.databaseName,
          schemaName: newRelatedObject?.schemaName,
          tableName: newRelatedObject?.name,
        },
        tag: "CRUD",
        type: "BLOCK_GROUP",
      };

      if (oldRelatedObject?.name === newModel?.name) {
        newModel.name = newRelatedObject?.name;
      }

      if (oldRelatedObject?.displayName === newModel?.description) {
        newModel.description = newRelatedObject?.displayName;
      }

      newModel.crudActions = crudActions;
    }

    onModelChange(newModel);
  };

  useEffect(() => {
    getAvailableObjects()
      .then(setAvailableRelatedObjects)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error. Cannot load available objects",
          location: "Backend. Available objects",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, []);

  return (
    <div style={style}>
      {title && <h3>{title}</h3>}
      <PropertiesTable
        options={getPropertiesTableSchema(availableRelatedObjects)}
        model={model}
        onModelChange={handleOnModelChange}
        customTypes={customTypes}
      />
    </div>
  );
};

DesigneeProperties.propTypes = {
  model: PropTypes.object,
  onModelChange: PropTypes.funcOf(PropTypes.object),
  style: PropTypes.object,
  title: PropTypes.string,
};

export default DesigneeProperties;
