// @flow

import { Box, Button, Tfoot, Tr } from "@chakra-ui/react";
import React, { useState } from "react";
import { Cell } from "../cell/cell";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import {
  DATAGRID_FOOTER_CELL_TESTID,
  DATAGRID_FOOTER_TESTID,
} from "../../utils/test-ids";
import { getResultData } from "../../utils/row-utils";
import { EditCell } from "../cell/edit-cell";
import { DisplayCell } from "../cell/display-cell";
import { FooterCell } from "../cell/footer-cell";
import { getBackground } from "../../utils/theme-utils";

export const Footer = ({ columns }): React.Node => {
  const {
    enableAdding,
    actions: { onRowAdd },
    selectionMode,
    theme,
  } = useDatagridContext();

  return (
    <Tfoot
      display="block"
      borderTop="1px solid"
      minHeight="fit-content"
      position="sticky"
      bottom="0"
      background={getBackground(theme)}
      data-testid={DATAGRID_FOOTER_TESTID}
    >
      <Tr>
        {columns.map((col) =>
          col.name === "checkbox" ? (
            selectionMode !== "none" && (
              <Cell
                column={col}
                width={col.width}
                key={`${col.name}-footer`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                data-testid={DATAGRID_FOOTER_CELL_TESTID}
              />
            )
          ) : (
            <FooterCell
              column={col}
              data-testid={DATAGRID_FOOTER_CELL_TESTID}
              key={`${col.name}-footer`}
            />
          ),
        )}
      </Tr>
    </Tfoot>
  );
};
