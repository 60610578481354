import { getSeparators } from "./split-format";

const digitsMinusRegex = /^[0-9-]+$/;

const digitsRegex = /^[0-9]+$/;
const zeroHashRegex = /^[#0]+$/;

export const showAlways = (c) => c === "0";
export const showGreaterThanZero = (c) => c === "#";

export const isDigitOrMinus = (char) => digitsMinusRegex.test(char);

export const testZeroHash = (str) => zeroHashRegex.test(str);

export const isDigit = (c) => digitsRegex.test(c);

const specialKeys = [
  "Spacebar",
  "Control",
  "Shift",
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "Backspace",
  "Home",
  "End",
  "Alt",
  "Tab",
  "Escape",
  "Delete",
];

export const isKeyAllowed = (k, format) => {
  const { decimal, group } = getSeparators(format);

  if (
    k === group ||
    k === decimal ||
    (decimal && k === ",") ||
    isDigitOrMinus(k) ||
    specialKeys.includes(k)
  )
    return true;

  return false;
};
