import { Box, Button, List, ListItem } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FiTrash2 } from "react-icons/fi";
import PropertiesTable from "../../../properties-table/properties-table";

export const ItemsList = (props) => {
  const {
    items,
    activeItem,
    onItemClick,
    updateItem,
    deleteItem,
    propertyTypes,
    inputTypes,
  } = props;

  return (
    <Box display="grid" gridTemplateColumns="repeat(2, 50%)" columnGap="5px">
      <Box>
        <List border="1px solid black" variant="standard" overflowY="auto">
          {items.map((element, index) => (
            <ListItem
              key={`row-cell-${index}`}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0px 8px 1px 8px",
                cursor: "pointer",
                borderBottom:
                  index === items.length - 1 ? "" : "1px solid black",

                fontWeight:
                  activeItem && activeItem.id === element.id
                    ? "bolder"
                    : undefined,
              }}
              onClick={() => onItemClick(element)}
            >
              <Box width="100%">{element.name}</Box>
              <Button
                variant="ghost"
                padding="0"
                margin="0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteItem(element);
                }}
              >
                <FiTrash2 color="#b0b1b2" size="16px" />
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        {activeItem && (
          <PropertiesTable
            options={propertyTypes}
            model={activeItem}
            onModelChange={updateItem}
            customTypes={inputTypes}
          />
        )}
      </Box>
    </Box>
  );
};

ItemsList.propTypes = {
  items: PropTypes.array,
  onItemChange: PropTypes.func,
  deleteItem: PropTypes.func,
  propertyTypes: PropTypes.array,
  inputTypes: PropTypes.array,
  activeItem: PropTypes.object,
  onItemClick: PropTypes.func,
  updateItem: PropTypes.func,
};
