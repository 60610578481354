import React, { useMemo } from "react";
import { Cell } from "./cell";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { formatValue } from "../../utils/format-number";
import { getTextColor } from "../../utils/theme-utils";

export const SummaryCell = (props) => {
  const { summaryOptions, summaryValues, theme } = useDatagridContext();
  const { column, width } = props;
  const { format } = column;
  const { actions } = summaryOptions;

  const summary = useMemo(() => {
    if (!summaryValues) return "";
    const f = summaryValues.find((el) => el.name === column.name);
    if (!f) return "";
    return formatValue(f.value.toString(), format || "# ##0.00");
  }, [summaryValues, column]);

  return (
    <Cell column={column} width={width}>
      <Box display="flex" alignItems="center">
        <Text width="100%">{summary}</Text>
        <Menu strategy="fixed">
          <MenuButton
            as={Button}
            variant="ghost"
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="0"
            padding="0"
            color={getTextColor(theme)}
          >
            <ChevronDownIcon style={{ margin: "0", padding: "0" }} />
          </MenuButton>
          <MenuList>
            {actions.map((a, idx) => (
              <MenuItem
                fontSize="1.2rem"
                _hover={{
                  background: "gray.200",
                }}
                background={
                  summaryValues.find(
                    (el) => el.name === column.name && el.type === a.type,
                  )
                    ? "gray.200"
                    : ""
                }
                onClick={() => {
                  if (a.action) a.action(column, a.type);
                }}
                key={idx}
              >
                {a.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Cell>
  );
};
