import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const MenuResizer = ({ onMouseUp, onMouseDown }) => {
  return (
    <Box
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      position="absolute"
      top="0"
      right="0"
      height="100%"
      width="5px"
      cursor="col-resize"
    />
  );
};

MenuResizer.propTypes = {
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
};
