import { useContext, useEffect, useState } from "react";
import {
  getCalendarsWithSettings,
  getData,
  mapCalendars,
  saveCalendarSetting,
} from "../utils/calendar-utils";
import { showErrorToast } from "../../../../../echo-error-toast";
import UserContext from "../../../../../../../user-context";

export const useCalendars = (props, component) => {
  const { userId, contextId, companyId } = useContext(UserContext);
  const { calendarDataSource, context } = props;

  const [calendars, setCalendars] = useState([]);

  const getCalendars = async (dataSource, context, component) => {
    try {
      const calendars = await getData(dataSource, context, component);
      const mappedCalendars = mapCalendars(calendars);
      const calendarsWithSettings = await getCalendarsWithSettings(
        mappedCalendars,
        userId || contextId,
        companyId,
        props.id,
      );
      setCalendars(calendarsWithSettings);
    } catch (e) {
      showErrorToast(e);
    }
  };
  const onCalendarVisibilityChange = async (
    calendarId,
    isVisible,
    settingId,
  ) => {
    const setting = { type: "isVisible", id: settingId, value: isVisible };

    setCalendars((prev) =>
      prev.map((el) =>
        el.id === calendarId
          ? { ...el, isVisible: { ...el.isVisible, value: isVisible } }
          : el,
      ),
    );

    await saveCalendarSetting(
      setting,
      calendarId,
      userId || contextId,
      companyId,
      props.id,
    );
  };

  const onCalendarBackgroundChange = async (
    background,
    calendarId,
    settingId,
  ) => {
    const setting = { type: "background", id: settingId, value: background };

    setCalendars((prev) =>
      prev.map((el) =>
        el.id === calendarId
          ? { ...el, background: { ...el.background, value: background } }
          : el,
      ),
    );

    await saveCalendarSetting(
      setting,
      calendarId,
      userId || contextId,
      companyId,
      props.id,
    );
  };

  useEffect(() => {
    if (calendarDataSource?.id)
      getCalendars(calendarDataSource, context, component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDataSource?.id]);

  return { calendars, onCalendarBackgroundChange, onCalendarVisibilityChange };
};
