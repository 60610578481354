const headerBackgroundDark = "#494949";
const headerBackgroundLight = "#dcdcdc";
const rowBackgroundDark = "#181818";
// const rowSelection = "#00aade";
// const rowSelection = "#85e3ff";
const rowSelection = "#61d6fa";

const fontWhite = "#fff";
const fontBlack = "#181818";

export const getBackground = (theme) => {
  switch (theme) {
    case "dark":
      return `${headerBackgroundDark} !important`;
    case "light":
    default:
      return `${fontWhite} !important`;
  }
};

export const getHeaderBackground = (theme) => {
  switch (theme) {
    case "dark":
      return `${headerBackgroundDark} !important`;
    case "light":
    default:
      return `${headerBackgroundLight} !important`;
  }
};

export const getTextColor = (theme) => {
  switch (theme) {
    case "dark":
      return `${fontWhite} !important`;
    case "light":
    default:
      return `${fontBlack} !important`;
  }
};

export const getRowBackgroundByIdx = (idx, theme) => {
  switch (theme) {
    case "dark":
      return idx % 2 === 0 ? `${rowBackgroundDark}` : `${headerBackgroundDark}`;
    case "light":
    default:
      return idx % 2 === 0 ? "#eeeeef" : "#ffffff";
  }
};

export const getRowTextColor = (row) => row?.__rowColor || undefined;

export const getSelectedRowBackground = (checked, currentBg) =>
  checked ? rowSelection : currentBg;
