// @flow
import * as React from "react";

import { Box, Text } from "@chakra-ui/react";
import { Pagination } from "./pagination";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { PageSizeSelect } from "./page-size-select";
import {
  DATAGRID_NAVIGATION_TESTID,
  DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID,
} from "../../utils/test-ids";
import { ContextActionSelect } from "../context-action-select/context-action-select";

export const Navigation = (): React.Node => {
  const { itemsCount, contextActions, selectedItems } = useDatagridContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      padding="5px"
      flexWrap="wrap"
      data-testid={DATAGRID_NAVIGATION_TESTID}
    >
      <Box display="flex" alignItems="center" gap="10px">
        <Text data-testid={DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID}>
          Total: {itemsCount}
        </Text>
        <Text data-testid={DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID}>
          Selected: {selectedItems.length}
        </Text>
      </Box>
      <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
        {contextActions && <ContextActionSelect />}
        <PageSizeSelect />
        <Pagination />
      </Box>
    </Box>
  );
};
