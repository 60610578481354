import { Quill } from "react-quill";
const Block = Quill.import("blots/block");

export class CustomParagraph extends Block {
  static create(value) {
    let node = super.create();
    if (value && value.id) {
      node.setAttribute("id", value.id);
    }
    return node;
  }

  static formats(domNode) {
    return domNode.hasAttribute("id")
      ? { id: domNode.getAttribute("id") }
      : undefined;
  }

  format(name, value) {
    if (name === "id") {
      if (value) {
        this.domNode.setAttribute("id", value);
      } else {
        this.domNode.removeAttribute("id");
      }
    } else {
      super.format(name, value);
    }
  }
}

CustomParagraph.blotName = "custom-paragraph";
CustomParagraph.tagName = "p";
