import { Box, Button } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

const getIsVisible = async (property) => {
  if (typeof property === "function") return await property();
  if (typeof property === "boolean") return property;
  if (typeof property === "object") {
    if (Array.isArray(property)) {
      return getIsVisible(property[0]);
    }

    if (property.value) {
      return getIsVisible(property.value);
    }

    return getIsVisible(Object.values(property)[0]);
  }

  if (property || property === 0) {
    return Boolean(property);
  }

  return true;
};

export const ActionsList = ({ actions, designerMode }) => {
  const [actionsList, setActionsList] = useState([]);
  const timeoutRef = useRef();

  const resolveIsVisible = useCallback(async () => {
    const promises = actions.map(async (a) => {
      const isVisible = await getIsVisible(a.isVisible);
      return { ...a, isVisible: await getIsVisible(isVisible) };
    });
    const arr = await Promise.all(promises);
    return arr;
  }, [actions]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (!designerMode) {
      timeoutRef.current = setTimeout(
        () => resolveIsVisible().then(setActionsList),
        500,
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [designerMode, resolveIsVisible]);

  return designerMode ? (
    <Box display="flex" alignItems="center" gap="10px">
      {actions &&
        actions.length > 0 &&
        actions.map((a, idx) => (
          <Button name={a?.name} style={a?.style} key={idx}>
            {a?.text}
          </Button>
        ))}
    </Box>
  ) : (
    <Box display="flex" alignItems="center" gap="10px">
      {actionsList &&
        actionsList.length > 0 &&
        actionsList.map((a, idx) => {
          return a.isVisible ? (
            <Button
              name={a?.name}
              onClick={() => {
                a?.onClick();
              }}
              style={a?.style}
              key={idx}
            >
              {a?.text}
            </Button>
          ) : null;
        })}
    </Box>
  );
};

ActionsList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
      style: PropTypes.object,
    }),
  ),
  designerMode: PropTypes.bool,
};
