import { useCallback, useContext, useMemo } from "react";
import { useSettingsReducer } from "../reducers/settings-reducer";
import UserContext from "../../../../../../../user-context";
import {
  getComponentSettings,
  saveComponentSettings,
} from "../../../../../../../services/settings-service";
import { useTimeoutEffect } from "./use-timeout-effect";
import { useComponentUnmount } from "../../../../../../../hooks/use-component-unmount";

const checkSorting = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  const changes = [];

  arr1.forEach((el, idx) => {
    if (el.name !== arr2[idx].name) {
      changes.push(el);
    } else if (el.name === arr2[idx].name && el.value !== arr2[idx].value) {
      changes.push(el);
    }
  });

  return changes.length > 0 ? false : true;
};

export const useSettings = ({
  id,
  dataSource,
  objectId,
  initialSettings,
  conditions,
}) => {
  const {
    userId: defaultUserId,
    contextId,
    companyId: defaultCompanyId,
  } = useContext(UserContext);

  const { state, setState, setField } = useSettingsReducer(initialSettings);

  const queryId = useMemo(
    () => dataSource?.staticParams?.queryId,
    [dataSource],
  );

  const groupId = useMemo(
    () => dataSource?.staticParams?.groupId,
    [dataSource],
  );

  const key = useMemo(
    () => `${objectId || "%"}_${queryId || "%"}_${groupId || "%"}`,
    [queryId, groupId, objectId],
  );

  const getOptions = useMemo(
    () => ({
      componentElementId: id,
      userId: contextId || defaultUserId,
      companyId: defaultCompanyId,
      key,
    }),
    [defaultCompanyId, defaultUserId, id, contextId, key],
  );

  const getSettings = useCallback(async () => {
    try {
      const res = await getComponentSettings(getOptions);
      const value = JSON.parse(res.value);
      const id = res.id;
      const key = res.key;
      return {
        id,
        key,
        ...value,
        sorting: conditions.disableSorting ? [] : value.sorting,
      };
    } catch (e) {
      return {
        id: null,
        key: null,
        columns: [],
        sorting: [],
        itemsPerPage: 20,
      };
    }
  }, [conditions.disableSorting, getOptions]);

  const getSaveOptions = useCallback(
    (value, userId, companyId) => ({
      componentElementId: id,
      userId: userId || userId === null ? userId : defaultUserId,
      companyId: companyId || companyId === null ? companyId : defaultCompanyId,
      id: state?.id || null,
      key: key,
      value: JSON.stringify(value),
    }),
    [id, defaultUserId, defaultCompanyId, state?.id, key],
  );

  const saveSettings = useCallback(
    async (settingsValue, userId, companyId, settingsType) => {
      try {
        const saveOptions = getSaveOptions(settingsValue, userId, companyId);
        const res = await saveComponentSettings(saveOptions, settingsType);
        const id = res.id;
        const key = res.key;
        const value = JSON.parse(res.value);
        return { ...value, id, key };
      } catch (e) {
        throw new Error(e);
      }
    },
    [getSaveOptions],
  );

  const updateState = async (settings, settingsType, companyId) => {
    return saveSettings(settings, settingsType, companyId).then((res) => {
      const { sorting } = res;
      if (checkSorting(sorting, state.sorting)) {
        Promise.all([
          setField("id", res.id),
          setField("key", res.key),
          setField("columns", res.columns),
          setField("itemsPerPage", res.itemsPerPage),
          setField("disableHeader", res.disableHeader),
          setField("disableFilters", res.disableFilters),
          setField("navPosition", res.navPosition),
          setField("headerWhiteSpace", res.headerWhiteSpace),
        ]);
      } else {
        setState(res);
      }
    });
  };

  const request = () => {
    setField("loading", true);
    getSettings()
      .then(setState)
      .finally(() => setField("loading", false));
  };

  useTimeoutEffect(request, [getSettings, dataSource?.id], dataSource, 1000);

  const saveState = useCallback(() => {
    if (!dataSource?.id) return;
    // eslint-disable-next-line no-unused-vars
    const { id, key, loading, ...rest } = state;
    saveSettings(rest);
  }, [dataSource?.id, saveSettings, state]);

  useComponentUnmount(saveState);

  return {
    settings: state,
    setSettings: updateState,
    setSetting: setField,
  };
};
