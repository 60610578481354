import { useContext, useMemo } from "react";
import UserContext from "../../../../../../../user-context";
import { getSelectAllActionLabel } from "../utils/get-select-all-action-label";
import { getSelectAllAction } from "../utils/get-select-all-action";

export const useContextActions = (state, api) => {
  const { isSuperuser } = useContext(UserContext);
  const { settings, itemsCount, selectedItems } = state;
  const {
    getData,
    selectAll,
    unselectAll,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onNavPositionChange,
    onColumnsManagerOpen,
    onHeaderWhiteSpaceChange,
    onColumnsSettingsOpen,
    onFilterChange,
    refreshData,
  } = api;

  const { disableFilters, disableHeader, navPosition, headerWhiteSpace } =
    settings;

  const selectAllRowsAction = useMemo(
    () => ({
      label: getSelectAllActionLabel(itemsCount, selectedItems),
      action: () =>
        getSelectAllAction(
          itemsCount,
          selectedItems,
          selectAll,
          unselectAll,
          getData,
        ),
    }),
    [getData, itemsCount, selectAll, selectedItems, unselectAll],
  );

  const headerVisilibityAction = useMemo(
    () => ({
      label: `${disableHeader ? "Show" : "Hide"} header`,
      action: () => onHeaderVisibilityChange(!disableHeader),
    }),
    [disableHeader, onHeaderVisibilityChange],
  );

  const filtersVisibilityAction = useMemo(
    () => ({
      label: `${disableFilters ? "Show" : "Hide"} filters`,
      action: () => onFiltersVisibilityChange(!disableFilters),
    }),
    [onFiltersVisibilityChange, disableFilters],
  );

  const navPositionAction = useMemo(
    () => ({
      label: `Change navigation position: ${navPosition === "bottom" ? "top" : "bottom"}`,
      action: () =>
        onNavPositionChange(navPosition === "bottom" ? "top" : "bottom"),
    }),
    [navPosition, onNavPositionChange],
  );

  const columnsManagerAction = useMemo(
    () => ({
      label: "Manage columns",
      action: onColumnsManagerOpen,
      color: "red",
      position: ["header"],
    }),
    [onColumnsManagerOpen],
  );

  const headerWhiteSpaceAction = useMemo(
    () => ({
      label: `Change header wrap: ${headerWhiteSpace === "normal" ? "no wrap" : "wrap"}`,
      action: () =>
        onHeaderWhiteSpaceChange(
          headerWhiteSpace === "normal" ? "nowrap" : "normal",
        ),
      position: ["header"],
    }),
    [onHeaderWhiteSpaceChange, headerWhiteSpace],
  );

  const columnSettingsAction = useMemo(
    () => ({
      label: "Advanced columns settings",
      action: onColumnsSettingsOpen,
      position: ["header"],
    }),
    [onColumnsSettingsOpen],
  );

  const clearFiltersAction = useMemo(
    () => ({
      label: "Clear filters",
      action: () => onFilterChange([]),
      position: ["header"],
    }),

    [onFilterChange],
  );

  const refreshDataAction = useMemo(
    () => ({
      label: "Refresh data",
      action: refreshData,
      position: ["header"],
    }),
    [refreshData],
  );

  const contextActions = [
    columnsManagerAction,
    isSuperuser && columnSettingsAction,
    clearFiltersAction,
    refreshDataAction,
    headerVisilibityAction,
    filtersVisibilityAction,
    navPositionAction,
    headerWhiteSpaceAction,
  ];

  const selectionContextActions = [selectAllRowsAction];

  return { contextActions, selectionContextActions };
};
