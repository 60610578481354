const checkIsTime = (format) => {
  if (!format) return false;
  const hasHour = format.includes("H") || format.includes("h");
  const hasMinute = format.includes("m");
  const hasTimeSeparator = format.includes(":");

  if (hasHour || hasMinute || hasTimeSeparator) return true;
  return false;
};

const checkIsDate = (format) => {
  if (!format) return false;
  const hasDay = format.includes("D") || format.includes("d");
  const hasMonth = format.includes("M");
  const hasYear = format.includes("Y") || format.includes("y");

  if (hasDay || hasMonth || hasYear) return true;
  return false;
};

export const checkFormat = (format) => {
  const isTime = checkIsTime(format);
  const isDate = checkIsDate(format);
  const isDateTime = isDate && isTime;

  return { isDate, isTime, isDateTime };
};
