import { useEffect, useMemo, useState } from "react";
import { resolveTabsProcess } from "../utils/resolve-tab-process";
import { mapTab } from "../utils/map-tab";

export const useTabs = (props) => {
  const [resolvedTabs, setResolvedTabs] = useState([]);
  const { tabs, context, ...rest } = props;

  const tabsWithProcess = useMemo(
    () =>
      tabs && tabs.length > 0 ? tabs.map((t) => mapTab(t, rest, context)) : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs],
  );

  useEffect(() => {
    if (!props.designerMode) {
      resolveTabsProcess(tabsWithProcess).then(setResolvedTabs);
    }
  }, [tabsWithProcess, props.designerMode]);

  return { resolvedTabs };
};
