import { useEffect, useMemo, useReducer } from "react";
import { ToastContainer, toast } from "react-toastify";
import MainPage from "./components/main-component/main-page";
import "./styles/App.css";
import UserContext from "./user-context";
import "react-toastify/dist/ReactToastify.css";
import "./styles/cursor/cursor.css";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { theme } from "./theme/themeCaldo";
import { getPermissions } from "./services/permission-service";
import { checkVersion } from "./utils/echo-version/echo-version";
import { registerErrorHandlers } from "./errors/error-handler";
import { getConfig } from "./services/echo-config-service";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "./utils/local-storage/local-storage";
import { localStorageKeys } from "./utils/local-storage/local-storage-keys";
import { getToastTimeConfig } from "./app-config/find-config";

const initialState = {
  login: null,
  token: null,
  companyId: null,
  tokenExpirationDate: null,
  metaData: null,
  userId: null,
  menuId: null,
};

const userReducer = (prevState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        login: action.login,
        token: action.token,
        companyId: action.companyId,
        tokenExpirationDate: action.tokenExpirationDate,
        metaData: action.metaData,
        userId: isNaN(action.userId) ? action.userId : Number(action.userId),
        menuId: action.menuId,
        permissions: action.permissions,
        contextId: action.contextId,
        contextLoginId: action.contextLoginId,
        isSuperuser: action.isSuperuser,
        isSourceContextSuperuser: action.isSourceContextSuperuser,
      };
    case "SIGN_OUT":
      return {
        login: null,
        token: null,
        companyId: null,
        tokenExpirationDate: null,
        metaData: null,
        userId: null,
        menuId: null,
        contextId: null,
        contextLoginId: null,
        isSuperuser: null,
        isSourceContextSuperuser: null,
      };
    case "REFRESH_TOKEN":
      return {
        ...prevState,
        token: action.token,
        tokenExpirationDate: action.tokenExpirationDate,
      };
    default:
      return prevState;
  }
};

function App() {
  registerErrorHandlers();

  const [userState, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    if (userState.login) checkVersion();
  }, [userState.login]);

  useEffect(() => {
    const jsonUserData = window.localStorage.getItem("user_data");
    const userData = JSON.parse(jsonUserData);

    if (userData?.sessionExpired) {
      toast.error(
        "Session expired. To continue working, please sign in again.",
      );
      window.localStorage.setItem("user_data", null);
    }

    if (userData && !userData.sessionExpired) {
      getPermissions(
        userData.companyId,
        userData.contextId || userData.userId,
      ).then((permissions) => {
        dispatch({
          type: "SIGN_IN",
          ...userData,
          permissions,
          tokenExpirationDate: new Date(userData.tokenExpirationDate),
        });
      });
    }
  }, []);

  const userContext = useMemo(
    () => ({
      signIn: (
        login,
        token,
        companyId,
        tokenExpirationDate,
        metaData,
        userId,
        menuId,
        contextId,
        contextLoginId,
        isSuperuser,
        isSourceContextSuperuser,
      ) => {
        // TODO Allow baseGet to use token as parameter instead of storing user_data twice
        window.localStorage.setItem(
          "user_data",
          JSON.stringify({
            login,
            token,
            companyId,
            tokenExpirationDate,
            metaData,
            userId: isNaN(userId) ? userId : Number(userId),
            menuId,
            permissions: [],
            contextId,
            contextLoginId,
            isSuperuser,
            isSourceContextSuperuser,
          }),
        );
        getPermissions(companyId, userId).then((permissions) => {
          window.localStorage.setItem(
            "user_data",
            JSON.stringify({
              login,
              token,
              companyId,
              tokenExpirationDate,
              metaData,
              userId: isNaN(userId) ? userId : Number(userId),
              menuId,
              permissions,
              contextId,
              contextLoginId,
              isSuperuser,
              isSourceContextSuperuser,
            }),
          );
          dispatch({
            type: "SIGN_IN",
            login,
            token,
            companyId,
            tokenExpirationDate,
            metaData,
            userId: isNaN(userId) ? userId : Number(userId),
            menuId,
            permissions,
            contextId,
            contextLoginId,
            isSuperuser,
            isSourceContextSuperuser,
          });
        });
      },
      signOut: () => {
        dispatch({ type: "SIGN_OUT" });
      },
      refreshToken: (token, tokenExpirationDate) =>
        dispatch({ type: "REFRESH_TOKEN", token, tokenExpirationDate }),
      isLoggedIn:
        userState.login !== "" &&
        userState.login !== null &&
        userState.token !== "" &&
        userState.token !== null &&
        userState.tokenExpirationDate > new Date(),
      userMetadata: userState.metaData,
      userName: userState.login,
      userId: userState.userId,
      contextId: userState.contextId,
      menuId: userState.menuId,
      companyId: userState.companyId,
      permissions: userState.permissions,
      isSuperuser: userState.isSuperuser,
      isSourceContextSuperuser: userState.isSourceContextSuperuser,
    }),
    [userState],
  );

  useEffect(() => {
    if (userState.login) {
      getConfig().then((res) => {
        const appConfig = res.map(({ code, value }) => ({ code, value }));
        setLocalStorageItem(localStorageKeys.APP_CONFIG, appConfig);
      });
    }
  }, [userState.login]);

  const toastTime = getToastTimeConfig();

  const echoTheme = getLocalStorageItem(localStorageKeys.CHAKRA_THEME);

  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <UserContext.Provider value={userContext}>
          <MainPage />
        </UserContext.Provider>
        <ToastContainer
          position="bottom-right"
          autoClose={toastTime ?? 3000}
          closeOnClick
          theme={echoTheme === "dark" ? "dark" : "light"}
        />
      </div>
    </ChakraProvider>
  );
}

export default App;
