import { getDatagridMaxSelectCount } from "../../../../../../../app-config/find-config";
import { echoPortal } from "../../../../../../echo-portal/echo-portal";
import QuestionAlertComponent from "../../question-alert-component/question-alert.component";
import { DEFAULT_MAX_SELECT_COUNT } from "./default-max-select-count";
import { getId } from "./get-id";

const selectAll = async (itemsCount, callback, queryFn) => {
  const maxSelectCount =
    getDatagridMaxSelectCount() ?? DEFAULT_MAX_SELECT_COUNT;

  const selectCount = maxSelectCount < itemsCount ? maxSelectCount : itemsCount;

  const promise = () =>
    new Promise((resolve) =>
      echoPortal.render((container) => (
        <QuestionAlertComponent
          open={true}
          header={`Zaznaczanie ${selectCount} wierszy.`}
          question={`Próbujesz zaznaczyć ${selectCount} rekordów. Czy na pewno chcesz kontynuować?`}
          alertMode={"Tak/Nie"}
          onAction={(type) => {
            container.unmount();
            resolve(type.toLowerCase());
          }}
        />
      )),
    );

  const res = await promise();
  if (res === "nie") return;

  const { data } = await queryFn({ page: 0, itemsPerPage: selectCount });
  callback(data.map((i) => getId(i)?.toString()));
};

export const getSelectAllAction = (
  itemsCount,
  selectedItems,
  selectFn,
  unselectFn,
  queryFn,
) => {
  const maxSelectCount =
    getDatagridMaxSelectCount() ?? DEFAULT_MAX_SELECT_COUNT;

  const selectCount = maxSelectCount < itemsCount ? maxSelectCount : itemsCount;

  if (selectCount === selectedItems.length) {
    return unselectFn();
  }

  return selectAll(itemsCount, selectFn, queryFn);
};
