import React, { useEffect, useState } from "react";
import changePropertyInArrayElement from "../../../../utils/change-property-in-array-element";
import PanelComponent from "./panel-component/panel-component";
import PropTypes from "prop-types";
import { Box, useColorMode } from "@chakra-ui/react";
import { getBackground } from "../../../../theme/utils";

const SideComponent = ({
  components,
  onComponentsChange,
  position,
  dragMode = false,
  setMoveButtons,
}) => {
  const { colorMode } = useColorMode();
  const [activeComponent, setActiveComponent] = useState(null);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (activeComponent && activeComponent.name) {
      setActiveComponent(
        components.find((c) => c.name === activeComponent.name),
      );
    }
  }, [activeComponent, components]);

  const hidePanel = () => {
    if (position === "right") setMoveButtons(false);
    setHide(true);
  };

  const openPanel = () => {
    if (position === "right") setMoveButtons(true);
    setHide(false);
  };

  const handleOnComponentChange = (
    componentName,
    propertyName,
    propertyValue,
  ) => {
    Promise.all([
      hidePanel(),
      setActiveComponent(null),
      onComponentsChange(
        changePropertyInArrayElement({
          array: components,
          searchIdentifier: "name",
          id: componentName,
          propertyName,
          newValue: propertyValue,
        }),
      ),
    ]);
  };

  const handleOnLabelButtonClick = (componentName) => {
    if (activeComponent?.name === componentName) {
      Promise.all([setActiveComponent(null), hidePanel()]);
    } else {
      const selectedComponentInfo = components.find(
        (c) => c.name === componentName,
      );
      Promise.all([setActiveComponent(selectedComponentInfo), openPanel()]);
    }
  };

  if (!components) {
    return null;
  }

  const availableComponents = components.filter(
    (panelInfo) => panelInfo.position === position,
  );

  const getBackgroundColor = (theme) => {
    if (theme === "light") return "#e1eff3";

    return getBackground(theme);
  };

  return (
    <Box
      className={`panel-component-container panel-component-${position} ${
        hide ? "panel-component-hidden-container" : ""
      } ${dragMode ? "hide-panel-component" : ""}`}
      style={{
        position: "absolute",
        [position]: 0,
        height: "100%",
        width:
          !availableComponents || !availableComponents.length
            ? "0px"
            : undefined,
      }}
    >
      <Box className="button-labels-container">
        {availableComponents.map((panelInfo) => {
          return (
            <PanelComponent
              key={`panel-component-${panelInfo.name}`}
              name={panelInfo.name}
              displayName={panelInfo.displayName}
              position={panelInfo.position}
              hide={hide}
              onLabelButtonClick={() =>
                handleOnLabelButtonClick(panelInfo.name)
              }
              onPositionChange={(position) =>
                handleOnComponentChange(panelInfo.name, "position", position)
              }
            />
          );
        })}
      </Box>
      <Box
        style={{
          display: "block",
          backgroundColor: dragMode ? "transparent" : undefined,
        }}
        background={getBackgroundColor(colorMode)}
        className={`panel-component-content ${
          !dragMode && !hide ? "panel-component-content-shadow" : undefined
        }`}
      >
        {activeComponent?.component}
      </Box>
    </Box>
  );
};

SideComponent.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      position: PropTypes.oneOf(["left", "right"]).isRequired,
      component: PropTypes.node,
    }),
  ).isRequired,
  onComponentsChange: PropTypes.func.isRequired,
  position: PropTypes.oneOf(["left", "right"]).isRequired,
  dragMode: PropTypes.bool,
  setMoveButtons: PropTypes.func,
};

export default SideComponent;
