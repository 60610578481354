import React, { useCallback, useEffect, useRef, useState } from "react";
import { EditCell } from "../cell/edit-cell";
import { Button, Tr, useOutsideClick } from "@chakra-ui/react";
import { Cell } from "../cell/cell";
import { DATAGRID_BODY_ROW_CELL_TESTID } from "../../utils/test-ids";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { getResultData } from "../../utils/row-utils";

export const EmptyRow = ({ columns, style }) => {
  const { columns: defaultColumns } = useDatagridContext();
  const ref = useRef();
  const {
    actions: { onRowAdd },
  } = useDatagridContext();

  const [rowState, setRowState] = useState({});

  const handleChange = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => ({ ...prev, ...result }));
    }
  };

  const handleSave = useCallback(async () => {
    if (!onRowAdd) return;
    await onRowAdd(rowState);
  }, [rowState, onRowAdd]);

  useEffect(() => {
    if (defaultColumns && defaultColumns.length > 0) {
      const initialObject = defaultColumns.reduce((acc, curr) => {
        if (curr.name === "checkbox") return acc;
        return { ...acc, [curr.name]: null };
      }, {});

      setRowState(initialObject);
    }
  }, []);

  return (
    <Tr
      ref={ref}
      style={{
        ...style,
        borderBottom: "1px solid black",
      }}
    >
      {columns.map((c, idx) =>
        c.name === "checkbox" ? (
          <Cell
            column={c}
            width={c.width}
            key={"checkbox-body"}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "4px 8px",
              justifyContent: "center",
            }}
            data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
          >
            <Button onClick={handleSave} width="" padding="0" margin="0">
              +
            </Button>
          </Cell>
        ) : (
          <EditCell
            column={c}
            autoFocus={idx === 1}
            row={rowState}
            value={rowState[c.name] || ""}
            onChange={handleChange}
          />
        ),
      )}
    </Tr>
  );
};
