export const checkUniqueNames = (elements) => {
  let arr = [];

  elements.forEach((el) => {
    const f = elements.filter(
      (elem) =>
        elem.name.toLowerCase() === el.name.toLowerCase() && elem.id !== el.id,
    );

    if (f && f.length > 0) {
      const alreadyExists = arr.find((elem) =>
        elem.find((e) => f.some((e2) => e2.id === e.id) || e.id === el.id),
      );

      if (!alreadyExists) arr.push([el, ...f]);
    }
  });

  return arr;
};
