import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { withBaseComponent } from "../../../with-base-component";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { DatePicker } from "@echo/ui";
import { Box, useColorMode } from "@chakra-ui/react";
import { getTextColor } from "../../../../../../theme/utils";

const InputDateComponent = ({
  rootProps,
  value,
  isReadOnly,
  isRequired,
  // eslint-disable-next-line react/prop-types
  isInvalid,
  designerMode,
  onChange = shimFunctions.shimFunction1,
  onFocusLost,
  label = "",
  inputFormat = "dd-MM-yyyy",
  disabled = false,
  variant = "outline",
  tabOrder,
}) => {
  const { nodeRef, onClick, style, ...restRootProps } = rootProps;
  const isReadOnlyState = useResolveProp(isReadOnly, true);

  const handleChange = async (arg) => {
    if (onChange && typeof onChange === "function") {
      await onChange(arg === "" ? null : arg);
    }

    if (onFocusLost && typeof onFocusLost === "function") {
      await onFocusLost(arg === "" ? null : arg);
    }
  };

  const handleClick = (e) => {
    if (!onClick || onClick === shimFunctions.shimFunction1) {
      return;
    }

    e.stopPropagation();

    onClick(e);
  };

  const {
    margin,
    transform,
    borderColor,
    borderStyle,
    borderWidth,
    width,
    height,
    order,
    ...rest
  } = style;

  const { colorMode } = useColorMode();

  return (
    <Box
      ref={nodeRef}
      onClick={handleClick}
      style={{
        margin,
        transform,
        borderColor,
        borderStyle,
        borderWidth,
        height,
        width,
        order,
      }}
      {...restRootProps}
    >
      <DatePicker
        name="date-input"
        date={value ? new Date(value) : ""}
        onChange={handleChange}
        isReadOnly={designerMode ? false : isReadOnlyState}
        label={label}
        format={inputFormat}
        isDisabled={disabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        tabIndex={parseFloat(tabOrder)}
        autoFocus={parseFloat(tabOrder) == 1}
        style={{ ...rest, color: getTextColor(colorMode) }}
      />
    </Box>
  );
};

InputDateComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.func,
  ]),
  onChange: PropTypes.func,
  onFocusLost: PropTypes.func,
  defaultValue: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isUnique: PropTypes.bool,
  inputFormat: PropTypes.string,
  tabOrder: PropTypes.string,
  variant: PropTypes.oneOf(["outline", "ghost"]),
  disabled: PropTypes.bool,
  rootProps: PropTypes.any,
  designerMode: PropTypes.bool,
};

export default withBaseComponent(InputDateComponent);
