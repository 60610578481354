import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { columnPropertyTypes } from "../utils/property-types";
import { inputTypes } from "../utils/input-types";
import { ItemsList } from "./items-list";
import { useItemsList } from "./hooks/use-items-list";

export const DatagridColumnsEditor = (props) => {
  const { propValue, designerPortalRef, onChange } = props;

  const {
    activeItem,
    addItem,
    deleteItem,
    list,
    onItemClick,
    restoreList,
    saveList,
    updateItem,
  } = useItemsList(propValue, onChange);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleCancel = () => {
    restoreList();
    onClose();
  };

  const handleSave = () => {
    saveList();
    onClose();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button onClick={onOpen} color="none" variant="ghost" size="md">
        {list.length}
        ...
      </Button>
      <Modal
        portalProps={{ containerRef: designerPortalRef }}
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <ItemsList
              items={list}
              onItemChange={updateItem}
              deleteItem={deleteItem}
              onItemClick={onItemClick}
              propertyTypes={columnPropertyTypes}
              inputTypes={inputTypes}
              activeItem={activeItem}
              updateItem={updateItem}
            />
            <Button onClick={addItem} variant="ghost">
              Add new column
            </Button>
          </ModalBody>
          <ModalFooter gap="20px">
            <Button onClick={handleCancel} variant="ghost">
              Cancel
            </Button>
            <Button onClick={handleSave} colorScheme="blue">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DatagridColumnsEditor.propTypes = {
  onChange: PropTypes.func,
  propName: PropTypes.string,
  propType: PropTypes.string,
  propValue: PropTypes.string,
  designerPortalRef: PropTypes.any,
};
