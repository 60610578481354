// import './wdyr';
import "./utils/prop-types/enhance-prop-types";
import "./utils/serialize/function/function-serializer";
import "./utils/order-by/order-by";
import "./styles/index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ColorModeScript } from "@chakra-ui/react";

createRoot(document.getElementById("root")).render(
  <>
    <ColorModeScript type="localStorage" initialColorMode="system" />
    <App />
  </>,
);
