import { useEffect, useState } from "react";
import { getData, mapEvents } from "../utils/calendar-utils";
import { showErrorToast } from "../../../../../echo-error-toast";

export const useEvents = (props, component) => {
  const { eventDataSource, context, onEventAdd } = props;

  const [events, setEvents] = useState([]);

  const getEvents = async (dataSource, context, component) => {
    try {
      const events = await getData(dataSource, context, component);
      const mappedEvents = mapEvents(events);
      setEvents(mappedEvents);
    } catch (e) {
      showErrorToast(e);
    }
  };

  const onEventEdit = (ev) => {
    if (!props.onEventEdit) {
      showErrorToast({
        reasonTitle: "Event not defined.",
        location: "Calendar",
        message: "Event onEventEdit is not defined.",
      });
      return;
    }

    props
      .onEventEdit(ev)
      .then(() => getEvents(eventDataSource, context, component));
  };

  useEffect(() => {
    if (eventDataSource?.id) getEvents(eventDataSource, context, component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDataSource?.id]);

  return { events, onEventAdd, onEventEdit };
};
