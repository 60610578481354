import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorMode,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

export const ChangeThemeModal = ({ isOpen, onClose }) => {
  const { colorMode, setColorMode } = useColorMode();

  const themes = ["light", "dark"];

  const handleChange = (e) => {
    const value = e.target.value;
    setColorMode(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change theme</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select value={colorMode} onChange={handleChange}>
            {themes.map((t) => (
              <option value={t} key={t}>
                {t}
              </option>
            ))}
          </Select>
        </ModalBody>

        <ModalFooter display="flex" gap="10px"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ChangeThemeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
