export const getAllElements = (data) => {
  const componentProps = data.componentProps;
  const componentName = data.component.name;
  const name = componentProps.name;
  const id = data.id;
  const children = data.childrenComponents;

  if (children && children.length > 0) {
    const map = children.map((c) => {
      const componentProps = c.componentProps;
      const componentName = c.component.name;
      const name = componentProps.name;
      const id = c.id;
      const children = c.childrenComponents;

      if (children && children.length > 0) return getAllElements(c).flat();

      return { id, name, componentName };
    });

    return [{ id, name, componentName }, ...map].flat();
  }

  return [{ id, name, componentName }];
};
