import PropTypes from "prop-types";
import PropertiesTable from "../properties-table/properties-table";
import EchoStateValueCell from "./echo-state-value-cell";
import EchoInputStateValueCell from "./echo-input-state-value-cell";
import EchoBoolValueCell from "../properties-table/value-cell/echo-bool-value-cell";
import EchoComboValueCell from "../properties-table/value-cell/echo-combo-value-cell";
import PermissionEditorEditCell from "../../permission-editor/permission-editor-edit-cell";
import { ListEventListenersSelect } from "./list/list-event-listeners-select";
import { DatagridColumnsEditor } from "./datagrid/datagrid-items-editor/datagrid-columns-editor";
import DatagridColumnsPropEditor from "./datagrid/datagrid-columns-prop-editor";
import { DatagridActionsEditor } from "./datagrid/datagrid-items-editor/datagrid-actions-editor";
import { DatagridRelatedObjectSelect } from "./datagrid/datagrid-related-object-select/datagrid-related-object-select";

const ComponentPropertiesTable = (props) => {
  const customTypes = [
    {
      name: "processSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <EchoStateValueCell
          propName={propName}
          propType={propType}
          propValue={propValue}
          onChange={onChange}
          stateSchema={props.stateSchema || {}}
          onStateSchemaChange={props.onStateSchemaChange}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "inputSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <EchoInputStateValueCell
          propName={propName}
          propType={propType}
          propValue={propValue}
          onChange={onChange}
          stateSchema={props.stateSchema || {}}
          onStateSchemaChange={props.onStateSchemaChange}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "boolSelect",
      component: ({ propName, propValue, onChange, propType }) => (
        <EchoBoolValueCell
          propName={propName}
          propType={propType}
          propValue={propValue}
          onChange={onChange}
          stateSchema={props.stateSchema || {}}
          onStateSchemaChange={props.onStateSchemaChange}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "comboSelect",
      component: ({ propValue, onChange, propName, propType }) => {
        const allowedValues = props.options?.find(
          (option) => option.propName === propName,
        )?.propTypeObject?.info?.allowedValues;
        return (
          <EchoComboValueCell
            enableProcessSelect
            propValue={propValue?.toString()}
            propType={propType}
            onChange={(selection) => onChange(selection || null)}
            options={(allowedValues || []).map((value) => ({
              label: value,
              value,
            }))}
            diagramParams={props.diagramParams}
          />
        );
      },
    },
    {
      name: "datagridColumnsPropSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <DatagridColumnsPropEditor
          propTypeObject={
            props.options?.find((option) => option.propName === propName)
              ?.propTypeObject
          }
          propValue={propValue}
          propType={propType}
          onChange={onChange}
          stateSchema={props.stateSchema || {}}
          componentProps={props.model}
          onStateSchemaChange={props.onStateSchemaChange}
        />
      ),
    },
    {
      name: "tabsPropSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <DatagridColumnsPropEditor
          propTypeObject={
            props.options?.find((option) => option.propName === propName)
              ?.propTypeObject
          }
          propValue={propValue}
          propType={propType}
          onChange={onChange}
          stateSchema={props.stateSchema || {}}
          componentProps={props.model}
          onStateSchemaChange={props.onStateSchemaChange}
          designerPortalRef={props.designerPortalRef}
          componentId={props.componentId}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "permissionEditorPropSelect",
      component: ({ propValue, onChange, model }) => (
        <PermissionEditorEditCell
          propValue={propValue}
          propTypes={props.options.filter(
            (prop) => prop.propType === "boolSelect",
          )}
          onChange={onChange}
          designerPortalRef={props.designerPortalRef}
          componentId={props.componentId}
          model={model}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "listEventListenersPropSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <ListEventListenersSelect
          elements={props?.model?.childs?.filter(
            (c) => c.component.name.toLowerCase() !== "button",
          )}
          propValue={propValue}
          onChange={onChange}
          propName={propName}
          propType={propType}
          diagramParams={props.diagramParams}
        />
      ),
    },
    {
      name: "datagridColumnsEditor",
      component: ({ propValue, onChange, propName, propType }) => (
        <DatagridColumnsEditor
          propValue={propValue || []}
          onChange={onChange}
          propName={propName}
          propType={propType}
          designerPortalRef={props.designerPortalRef}
          componentId={props.componentId}
        />
      ),
    },
    {
      name: "datagridActionsEditor",
      component: ({ propValue, onChange, propName, propType, model }) => (
        <DatagridActionsEditor
          model={model}
          propValue={propValue || []}
          onChange={onChange}
          propName={propName}
          propType={propType}
          designerPortalRef={props.designerPortalRef}
          componentId={props.componentId}
        />
      ),
    },
    {
      name: "relatedObjectIdSelect",
      component: ({ propValue, onChange, propName, propType }) => (
        <DatagridRelatedObjectSelect
          propValue={propValue || ""}
          onChange={onChange}
          propName={propName}
          propType={propType}
          componentId={props.componentId}
        />
      ),
    },
  ];

  return <PropertiesTable {...props} customTypes={customTypes} />;
};

ComponentPropertiesTable.propTypes = {
  componentId: PropTypes.number,
  options: PropTypes.array,
  model: PropTypes.object,
  onModelChange: PropTypes.func,
  schema: PropTypes.array,
  stateSchema: PropTypes.object,
  onStateSchemaChange: PropTypes.func.isRequired,
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  diagramParams: PropTypes.object,
};

export default ComponentPropertiesTable;
