import { jwtDecode } from "jwt-decode";
import { refreshToken } from "../../services/token-service";

export const getNewTokenData = async (token) => {
  const newToken = await refreshToken(token);
  const tokenData = jwtDecode(newToken);
  const newExpirationDate = new Date(0);
  newExpirationDate.setUTCSeconds(tokenData.exp);

  return {
    token: newToken,
    tokenData,
    tokenExpirationDate: newExpirationDate,
  };
};
