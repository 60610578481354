import { showErrorToast } from "../../../shared/echo-error-toast";

export const handleSameElementsError = (elements) => {
  const res = elements.reduce((acc, curr) => {
    const name = curr[0].name;
    const elements = curr.map((el) => ({
      id: el.id,
      element: el.componentName,
    }));

    return { ...acc, [name]: elements };
  }, {});

  let msg = `There are elements with the same names. \n`;

  Object.keys(res).forEach((k) => {
    msg += `${k}: [ `;
    res[k].forEach((el, idx) => {
      msg += `${el.element}: ${el.id}${idx === res[k].length - 1 ? "" : ", "}`;
    });
    msg += ` ] \n`;
  });

  showErrorToast({
    reasonTitle: "Duplicate names.",
    location: "Component designer.",
    shortMessage: "There are elements with same names.",
    message: msg,
  });
};
