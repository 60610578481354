import { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { withBaseComponent } from "../../../with-base-component";
import { Input } from "@echo/ui";
import {
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import DatagridV2Component from "../datagrid-v2-component/datagrid-v2.component";

const ComboGridComponent = forwardRef(
  (
    {
      label,
      id,
      isReadOnly,
      isDisabled,
      placeholder,
      onClick,
      value,
      onRowClick,
      onRowDoubleClick,
      style,
      gridPageSizes,
      name,
      dataSource,
      context,
      rootProps,
      filterColumnName,
      onFilterChange,
      headerFontSize,
      rowFontSize,
      hideFooter,
      onSelectChange,
      cellWhiteSpace,
      cellLinesNumber,
      selectionMode,
      isRequired,
      isInvalid,
      onChange = shimFunctions.shimFunction0,
      gridWidth = "100%",
    },
    ref,
  ) => {
    const { isOpen, onClose, onToggle, onOpen } = useDisclosure();

    const [inputValue, setInputValue] = useDebouncedValueState(
      (value || "").toString(),
      onChange,
      name,
    );

    const isDisabledState = useResolveProp(isDisabled, true);
    const isReadOnlyState = useResolveProp(isReadOnly, true);
    const isRequiredState = useResolveProp(isRequired, true);
    const { nodeRef, ...restRootProps } = rootProps;

    const popoverRef = useRef();
    useOutsideClick({
      ref: popoverRef,
      handler: onClose,
    });

    const dataGridProps = {
      id,
      name: `${name}_DataGrid`,
      context,
      dataSource,
      style: {
        ...restRootProps.style,
        width: gridWidth,
        maxHeight: "400px",
        whiteSpace: style?.whiteSpace,
      },
    };

    const gridPageSizesState = useResolveProp(gridPageSizes, true);

    const pageSizes = gridPageSizesState
      ?.split(",")
      .map((el) => ({ value: parseFloat(el), label: el }));

    const defaultPageSizes = [
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
    ];

    const pageSizeSelectOptions = gridPageSizes ? pageSizes : defaultPageSizes;

    const handleRowClick = (row) => {
      if (onRowClick && typeof onRowClick === "function") {
        onRowClick(row);
        onClose();
      }
    };

    const handleRowDoubleClick = (row) => {
      if (onRowDoubleClick && typeof onRowDoubleClick === "function") {
        onRowDoubleClick(row);
        onClose();
      }
    };

    const handleInputChange = (e) => {
      if (inputValue !== e.target.value) {
        onOpen();
        if (typeof onFilterChange === "function") {
          Promise.all([
            setInputValue(e.target.value),
            onFilterChange(e.target.value),
            ref.current.target.focus(),
          ]);
        } else {
          setInputValue(e.target.value);
        }
      }
    };

    const handleScrollToElement = (element) => {
      const rect = element.getBoundingClientRect();
      const elementBottomBorder = rect.bottom;
      const windowHeight = window.innerHeight;
      const isInView = elementBottomBorder < windowHeight;

      if (!isInView) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    };

    useEffect(() => {
      if (isOpen && popoverRef.current) {
        handleScrollToElement(popoverRef.current);
      }
    }, [isOpen]);

    return (
      <Popover
        isOpen={isOpen}
        autoFocus={false}
        onClose={onClose}
        initialFocusRef={popoverRef}
        matchWidth
        flip={false}
      >
        <PopoverTrigger>
          <Input
            id={id}
            ref={ref}
            autoComplete="off"
            // onBlur={() => setPopoverOpen(false)}
            name={name}
            label={label}
            placeholder={placeholder}
            value={inputValue?.toString()}
            onChange={handleInputChange}
            isReadOnly={isReadOnlyState}
            isDisabled={isDisabledState}
            isRequired={isRequiredState}
            isInvalid={isInvalid}
            nodeRef={nodeRef}
            onClick={onClick}
            icon={
              <IconButton
                variant="ghost"
                onClick={onToggle}
                isDisabled={isDisabledState || isReadOnlyState}
                style={{
                  fontSize: "1.25rem",
                  position: "absolute",
                  top: "50%",
                  right: "4px",
                  transform: "translateY(-50%)",
                  zIndex: "10",
                }}
                icon={<ChevronDownIcon />}
              />
            }
            {...restRootProps}
          />
        </PopoverTrigger>
        <PopoverContent width="100%" ref={popoverRef}>
          <PopoverBody height="450px" overflow="hidden">
            <DatagridV2Component
              {...dataGridProps}
              pageSizeSelectOptions={pageSizeSelectOptions}
              onRowClick={handleRowClick}
              onRowDoubleClick={handleRowDoubleClick}
              isCombo
              hideFilterRow
              filterColumnName={filterColumnName}
              filterValue={inputValue}
              headerFontSize={headerFontSize}
              rowFontSize={rowFontSize}
              hideFooter={hideFooter}
              onSelectChange={onSelectChange}
              cellWhiteSpace={cellWhiteSpace}
              cellLinesNumber={cellLinesNumber}
              selectionMode={selectionMode}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

ComboGridComponent.displayName = "ComboGridComponent";

ComboGridComponent.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  dataSource: PropTypes.any,
  onChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  editable: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  gridPageSizes: PropTypes.string,
  gridWidth: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  rootProps: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  type: PropTypes.any,
  style: PropTypes.object,
  onClick: PropTypes.funcOf(PropTypes.object),
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  context: PropTypes.any,
  colorScheme: PropTypes.any,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  size: PropTypes.any,
  filterColumnName: PropTypes.string,
  onFilterChange: PropTypes.func,
  headerFontSize: PropTypes.string,
  rowFontSize: PropTypes.string,
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,
  hideFooter: PropTypes.bool,
  onSelectChange: PropTypes.func,
};

export default withBaseComponent(ComboGridComponent);
