// @flow

import * as React from "react";

import {
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { getTextColor } from "../../utils/theme-utils";
import { useDatagridContext } from "../../hooks/useDatagridContext";

type FilterProps = {
  options: [],
  selected: {},
  onChange: () => void,
};

export const Filter = (props: FilterProps): React.Node => {
  const { theme } = useDatagridContext();
  const { options, selected, onChange } = props;

  const getIsChecked = (selectedOptions, option) => {
    const found = selectedOptions.find((opt) => opt === option.value);
    return found ? true : false;
  };

  return (
    <Popover placement="bottom" variant="responsive" isLazy strategy="fixed">
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          margin="0"
          padding="4px 0px 4px 4px"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          color={getTextColor(theme)}
          width="fit-content"
          icon={
            <FiFilter
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          }
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader height="fit-content" padding="5px">
          Opcje filtrowania
        </PopoverHeader>
        <PopoverBody padding="5px">
          <List display="flex" flexDirection="column" width="100%" gap="5px">
            {options.map((option) => (
              <ListItem key={option.id}>
                <Checkbox
                  isChecked={option.value === selected}
                  value={option.value}
                  onChange={onChange}
                >
                  {option.label}
                </Checkbox>
              </ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
