import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, MenuList } from "@chakra-ui/react";

function getAbsPosition(element) {
  var rect = element.getBoundingClientRect();
  return { x: rect.left, y: rect.top };
}

const ContextMenu = ({
  isOpen,
  onClose,
  scale = 1,
  clickPosition,
  actions,
}) => {
  const listRef = useRef(null);

  useEffect(() => {
    const menu = listRef?.current;
    if (menu) {
      const popper = menu.parentElement;

      Object.assign(popper.style, {
        top: `0px`,
        left: `0px`,
      });

      const { x: childX, y: childY } = getAbsPosition(popper);
      const x = clickPosition.x - childX;
      const y = clickPosition.y - childY;

      Object.assign(popper.style, {
        top: `${y}px`,
        left: `${x}px`,
        minWidth: "max-content",
        height: "auto",
        width: "auto",
      });

      if (scale) {
        Object.assign(popper.style, {
          transform: `scale(${scale})`,
        });
      }
    }
  });

  const handleOnClick = (action, e) => {
    e.stopPropagation();
    if (action && typeof action === "function") {
      action(e);
    }
  };

  return (
    <Menu
      strategy="fixed"
      isOpen={isOpen}
      onClose={() => {
        const menu = listRef?.current;
        const popper = menu.parentElement;

        Object.assign(popper.style, {
          height: "0px",
          width: "0px",
          minWidth: "0px",
          inset: "0px auto auto 0px",
        });
        onClose(false);
      }}
    >
      <MenuList
        ref={listRef}
        scale={5}
        onAnimationEnd={() => {
          const menu = listRef?.current?.parentElement;
          if (menu) {
            menu.focus();
          }
        }}
      >
        {Array.isArray(actions) &&
          actions.map(({ label, action, color }, index) => {
            return (
              <MenuItem
                key={`action-${label}-${index}`}
                onClick={(e) => handleOnClick(action, e)}
                color={color || "black"}
              >
                {label}
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};
ContextMenu.displayName = "ContextMenu";

ContextMenu.propTypes = {
  scale: PropTypes.number,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  clickPosition: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func,
      color: PropTypes.string,
    }),
  ),
};

// stara wersja zostawiona narazie
// używane w GraphDesigner, nie potrafię dojść do graph designera w echo ~Olek

export default ContextMenu;
