import { pl } from "date-fns/locale";
import { getPartsFormats, getPartsRanges } from "./get-parts-ranges";
import { getSeparator } from "./get-separator";
import { getCursorPos } from "./validate-character";
import { format as formatDate } from "date-fns";

export const formatDateValue = (e, value, format) => {
  if (value === "") return value;

  const pos = getCursorPos(e);
  const { usedSeparators, separators } = getSeparator(format, value, pos);
  const formatParts = getPartsFormats(format);
  const ranges = getPartsRanges(value, usedSeparators);
  const helperDate = new Date();
  const splittedValue = value.split("");

  const formattedRanges = ranges.map((r) => {
    const format = formatParts.find((p) => r.id === p.id);
    const helperFormattedDate = formatDate(helperDate, format.format, {
      locale: pl,
    });
    const digitsCount = helperFormattedDate.length;

    const part = splittedValue.filter(
      (_, idx) => idx >= r.start && idx < r.end,
    );

    let formattedPart = "";

    if (part.length < digitsCount) {
      for (let i = 0; i < digitsCount - part.length; i++) {
        formattedPart += "0";
      }
      part.forEach((v) => {
        formattedPart += v;
      });
    } else {
      formattedPart = part.join("");
    }

    return formattedPart;
  });

  return formattedRanges.join(separators[0].char);
};
