import { executeBlock } from "../../../../../../../process-executor/process-executor";

const getQueryParams = (paramsArr) => {
  const first = paramsArr[0];
  const keys = Object.keys(first);

  return keys.map((k) => ({ name: k, value: first[k] }));
};

const calculateProp = (prop, componentContext, props) => {
  if (prop && prop.type) {
    switch (prop.type) {
      case "PROCESS": {
        const processFunc = (...params) =>
          executeBlock(
            prop.context || componentContext,
            prop.id,
            prop.staticParams ?? {
              queryParams: params,
            },
            params,
            {
              name: props.name,
              source: prop.source,
            },
          );
        processFunc.processId = prop.id;
        return processFunc;
      }
      default:
        return prop;
    }
  }
  return prop;
};

const actionMapper = (c, props) => {
  const keys = Object.keys(c);
  return keys.reduce((acc, curr) => {
    if (c[curr].type && c[curr].type === "PROCESS") {
      const { context } = props;
      const prop = calculateProp(c[curr], context, props);
      return { ...acc, [curr]: prop };
    }
    return { ...acc, [curr]: c[curr] };
  }, {});
};

export const resolveActionProcess = (actions, props) =>
  actions.map((c) => actionMapper(c, props));
