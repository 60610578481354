import { calculateProp } from "../../../../../../../echo-component/function-wrapper/calculate-prop";

export const mapTab = (tab, props, ctx) => {
  if (!tab) return null;

  const keys = Object.keys(tab);

  if (keys.length === 0) return null;

  return keys.reduce((acc, curr) => {
    if (tab[curr] && tab[curr].type && tab[curr].type === "PROCESS") {
      const func = calculateProp(tab[curr], ctx, props);
      return { ...acc, [curr]: func };
    }

    return { ...acc, [curr]: tab[curr] };
  }, {});
};
