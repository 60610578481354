import { useCallback, useMemo } from "react";
import { resolveActionProcess } from "../utils/resolve-actions";
import { getId } from "../utils/get-id";
import { showErrorToast } from "../../../../../echo-error-toast";

export const useActions = (props, state, api) => {
  const { selectedItems, data } = state;
  const { name, id, designerMode, actions } = props;
  const { refreshData, addEmptyRow } = api;

  const resolvedActions = useMemo(() => {
    if (!designerMode && actions) {
      return resolveActionProcess(actions, props);
    }
    return [];
  }, [actions, designerMode, props]);

  const addParamsToEvents = useCallback(
    (actions) =>
      actions.map((a) => ({
        ...a,
        onClick: async () => {
          if (a.onClick && typeof a.onClick === "function") {
            try {
              await a.onClick(
                { name: "datagrid_id", value: id },
                { name: "datagrid_name", value: name },
                {
                  name: "datagrid_selection",
                  value: JSON.stringify(selectedItems),
                },
                {
                  name: "datagrid_data",
                  value: JSON.stringify(data.map(getId)),
                },
              );
              if (a.refreshData) await refreshData();
            } catch (e) {
              showErrorToast(e);
            }
          }
        },
      })),
    [data, refreshData, selectedItems, id, name],
  );

  const addNewRowBtn = useCallback(
    (actions) =>
      actions.map((a) =>
        a.name.toLowerCase() === "addrowbtn"
          ? { ...a, onClick: addEmptyRow }
          : a,
      ),
    [addEmptyRow],
  );

  const mappedActions = useMemo(() => {
    const withEvents = addParamsToEvents(resolvedActions);

    if (props.enableAdding) {
      const withRowAddBtn = addNewRowBtn(withEvents);
      return withRowAddBtn;
    }

    return withEvents;
  }, [resolvedActions, addParamsToEvents, addNewRowBtn, props.enableAdding]);

  return { actions: mappedActions };
};
