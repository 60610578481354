import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../../user-context";
import {
  getComponentSettings,
  saveComponentSettings,
} from "../../../services/settings-service";

export const useMenuSettings = (defaultWidth) => {
  const menuSettingsKey = "sidebar-menu";

  const [settingsId, setSettingsId] = useState(null);
  const [width, setWidth] = useState();

  const { contextId, userId, companyId } = useContext(UserContext);

  const saveSettings = useCallback(
    async (width) => {
      await saveComponentSettings({
        id: settingsId || null,
        componentElementId: 0,
        userId: contextId || userId,
        companyId,
        key: menuSettingsKey,
        value: JSON.stringify(width),
      });
    },
    [companyId, contextId, settingsId, userId],
  );

  const getSettings = useCallback(
    async () =>
      await getComponentSettings({
        id: settingsId,
        componentElementId: 0,
        userId: contextId || userId,
        companyId,
        key: menuSettingsKey,
      }),
    [settingsId, userId, contextId, companyId],
  );

  useEffect(() => {
    getSettings()
      .then((res) => {
        const width = parseInt(res.value);
        const id = res.id;

        setSettingsId(id);

        if (width > window.innerWidth) setWidth(defaultWidth);
        else setWidth(width);
      })
      .catch((e) => {
        setWidth(defaultWidth);
        console.error(e);
      });
  }, [getSettings, defaultWidth]);

  useEffect(() => {
    if (width) {
      saveSettings(width);
    }
  }, [saveSettings, width]);

  return [width, setWidth];
};
