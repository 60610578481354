import { toast } from "react-toastify";
import { authenticate } from "../../../services/email-service/email-service";
import getToken from "../../../services/token-service";

export const sendEmailBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { to, subject, text, html, attachments, cc, bcc } = staticParams;
      const userId = componentContext.userContext.userId;

      const { email: user, refreshToken } = await authenticate(userId);

      if (!user) return {};

      var formData = new FormData();

      formData.append("user", user);
      formData.append("refreshToken", refreshToken);
      formData.append("to", to || "");
      formData.append("cc", cc || "");
      formData.append("bcc", bcc || "");
      formData.append("subject", subject || "");
      formData.append("text", text || "");
      formData.append("html", html || "");

      if (attachments && attachments.length > 0) {
        attachments.forEach((att) => {
          formData.append(`files`, att);
        });
      }

      const request = async () =>
        await fetch(
          // eslint-disable-next-line no-undef
          process.env.EMAIL_SERVICE_URL + "messages/send-message",
          {
            method: "POST",
            headers: { authorization: `Bearer ${getToken()}` },
            body: formData,
          },
        ).then(async (res) => {
          if (!res.ok) {
            const err = await res.json();
            throw err;
          }
          return await res;
        });

      const res = await toast.promise(
        request,
        {
          pending: "Wysyłanie wiadomości.",
          success: "Wysłano.",
        },
        { toastId: "send-message-toast" },
      );

      const data = await res.json();

      if (data.messageId && data.messageId !== "")
        return { messageId: data.messageId };

      return {};
    },
  };
};
