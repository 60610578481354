const getDefinitionParts = (def) => {
  const charArr = def.split("");

  let tempArr = [];
  let index = 0;

  charArr.forEach((c) => {
    if (c === "<" || c === ">") {
      index++;
      return;
    }

    if (!tempArr[index]) {
      tempArr[index] = "";
    }

    tempArr[index] += c;
  });

  return tempArr;
};

const getNumeration = (parts, state, definition) => {
  const stateKeys = Object.keys(state || {});
  const docDateKey =
    Object.keys(state || {}).find((k) => k.toLowerCase() === "docdate") ||
    "docDate";

  const res = parts.map((p) => {
    const f = stateKeys.find((k) => k.toLowerCase() === p.toLowerCase());

    switch (p) {
      case f:
        return state[f];
      case "symbol":
        return definition.code;
      case "auto":
        return "<auto>";
      case "year":
        return state && state[docDateKey]
          ? new Date(state[docDateKey]).getFullYear()
          : new Date().getFullYear();
      case "month":
        return state && state[docDateKey]
          ? new Date(state[docDateKey]).getMonth() + 1
          : new Date().getMonth() + 1;
      default:
        return p;
    }
  });

  return res.join("");
};

export const generateNumeration = (state, definition) => {
  var def = definition.numeration;
  if (!(def.includes("<") && def.includes(">"))) return def;

  const parts = getDefinitionParts(def);
  return getNumeration(parts, state, definition);
};
