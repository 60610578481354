import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../echo-error-toast";
import { formats, modules } from "./editor-config";
import { CustomParagraph } from "./custom-paragraph";
import { CustomClipboard } from "./custom-clipboard";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";

const Parchment = Quill.import("parchment");
Parchment.register(CustomParagraph);
Quill.register("modules/clipboard", CustomClipboard, true);

const WysiwygComponent = ({ rootProps, value, onChange, name, readOnly }) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [valueQuill, setValueQuill] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const [readOnlyValue, setReadOnlyValue] = useState(true);

  useEffect(() => {
    Promise.resolve(typeof readOnly === "function" ? readOnly() : readOnly)
      .then(setReadOnlyValue)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Cannot read readOnly prop",
          location: "WYSIWYG component",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, [readOnly]);

  return (
    <Box {...restRootProps} ref={nodeRef} className="wysiwyg">
      <ReactQuill
        value={valueQuill}
        onChange={setValueQuill}
        readOnly={readOnlyValue}
        formats={formats}
        modules={modules}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        placeholder="Write text here"
        theme="snow"
      />
    </Box>
  );
};

WysiwygComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rootProps: PropTypes.object,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default withBaseComponent(WysiwygComponent);
