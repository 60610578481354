// @flow

import * as React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { format, isToday, compareAsc, isSameDay } from "date-fns";
import { CalendarNav } from "./calendar-nav";

const WEEKDAYS_PL = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];

const CalendarGridHeader = () =>
  WEEKDAYS_PL.map((el) => (
    <GridItem
      paddingBottom="5px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      key={el}
    >
      {el}
    </GridItem>
  ));

type GridProps = {
  calendarDays: { type: "PREV" | "CURRENT" | "NEXT", days: Date[] }[],
  onDateSelected: (date: Date) => void,
  selectedDates: Date[],
};

const CalendarGrid = (props: GridProps) => {
  const { selectedDates, calendarDays, onDateSelected } = props;

  const getIsSelected = (date: Date, selectedDates: Date[]) => {
    const found = selectedDates.find((d) => isSameDay(date, new Date(d)));
    return found ? true : false;
  };

  return (
    <Grid templateColumns={"repeat(7, 1fr)"} gap="5px">
      <CalendarGridHeader />
      {calendarDays.map((el) =>
        el.days.map((day, idx) => (
          <GridItem
            padding="5px 0"
            cursor="pointer"
            aspectRatio="1/1"
            onClick={() => onDateSelected(day)}
            border={isToday(day) ? "2px solid #8075FF !important" : ""}
            background={getIsSelected(day, selectedDates) ? "#8075FF" : ""}
            fontWeight={getIsSelected(day, selectedDates) ? "bold" : "normal"}
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            key={idx}
            _hover={{ background: "#DDDDDD" }}
            color={el.type === "PREV" || el.type === "NEXT" ? "#AAA" : "black"}
          >
            {format(day, "d")}
          </GridItem>
        )),
      )}
    </Grid>
  );
};

type CalendarProps = {
  date: Date,
  calendarDays: Date[],
  onDateChange: () => void,
  onDateSelected: (date: Date) => void,
  selectedDates: Date[],
};

export const Calendar = (props: CalendarProps) => {
  const { selectedDates, calendarDays, date, onDateChange, onDateSelected } =
    props;
  return (
    <Box display="flex" flexDirection="column" padding="10px" gap="5px">
      <CalendarNav date={date} onDateChange={onDateChange} />
      <CalendarGrid
        calendarDays={calendarDays}
        onDateSelected={onDateSelected}
        selectedDates={selectedDates}
      />
    </Box>
  );
};
