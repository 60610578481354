import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { uuidv4 } from "@echo/tools";

const LabeledSelectFormControl = forwardRef(
  (
    {
      children,
      nodeRef,
      value,
      defaultValue,
      label,
      rootProps,
      isReadOnly,
      onChange,
      isRequired,
      isInvalid,
      fontSize,
      ...restProps
    },
    ref,
  ) => {
    const labelId = uuidv4();

    const getValue = () => {
      if (isReadOnly) {
        return value ?? defaultValue ?? "";
      } else {
        return value;
      }
    };

    const handleOnChange = (...args) => {
      if (!isReadOnly && onChange) {
        onChange(...args);
      }
    };

    return (
      labelId && (
        <FormControl
          ref={nodeRef}
          variant={"floating"}
          height="fit-content"
          isInvalid={isInvalid}
          isRequired={isRequired}
          {...rootProps}
        >
          {label && (
            <FormLabel id={`select-label-${labelId}`}>{label}</FormLabel>
          )}
          <Select
            sx={{
              pointerEvents: isReadOnly ? "none" : undefined,
              padding: "0 5px",
            }}
            rootProps={{
              className: value ? "combo-select-value" : "",
            }}
            width="100%"
            ref={ref}
            id={`select-label-${labelId}`}
            height="100%"
            value={getValue()}
            isRequired
            onChange={handleOnChange}
            {...restProps}
            fontSize={fontSize}
          >
            {children}
          </Select>
        </FormControl>
      )
    );
  },
);

LabeledSelectFormControl.displayName = "LabeledSelectFormControl";

LabeledSelectFormControl.propTypes = {
  children: PropTypes.node,
  nodeRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string,
  rootProps: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  }),
  fontSize: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

export default LabeledSelectFormControl;
