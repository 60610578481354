import { getDatagridMaxSelectCount } from "../../../../../../../app-config/find-config";
import { DEFAULT_MAX_SELECT_COUNT } from "./default-max-select-count";

export const getSelectAllActionLabel = (itemsCount, selectedItems) => {
  const maxSelectCount =
    getDatagridMaxSelectCount() ?? DEFAULT_MAX_SELECT_COUNT;

  if (maxSelectCount < itemsCount) {
    return `${selectedItems.length === maxSelectCount ? "Odznacz" : "Zaznacz"} ${maxSelectCount} wierszy`;
  }

  return `${selectedItems.length === itemsCount ? "Odznacz" : "Zaznacz"} wszystkie (${itemsCount} wierszy)`;
};
