import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { localStorageKeys } from "../utils/local-storage/local-storage-keys";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/local-storage/local-storage";
import { toast } from "react-toastify";
import { Box, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { getExpirationTime } from "../utils/token-expiration/get-expiration-time";
import {
  expirationToastId,
  minute,
  second,
} from "../utils/token-expiration/token-expiration-utils";
import UserContext from "../user-context";
import { getNewTokenData } from "../utils/token-expiration/get-new-token-data";
import {
  getCheckSessionIntervalTime,
  getSessionRefreshTimeConfig,
} from "../app-config/find-config";

const userDataKey = localStorageKeys.USER_DATA;

const storeToken = (token, expirationDate) => {
  const userData = getLocalStorageItem(userDataKey);
  setLocalStorageItem(userDataKey, {
    ...userData,
    token,
    tokenExpirationDate: expirationDate,
  });
};

export const useTokenExpiration = () => {
  const sessionRefreshTime = getSessionRefreshTimeConfig();
  const checkSessionIntervalTime = getCheckSessionIntervalTime();

  const [expirationTime, setExpirationTime] = useState(null);
  const toastUpdateInterval = useRef(null);
  const checkExpirationInterval = useRef(null);

  const { refreshToken: refreshContextToken, signOut } =
    useContext(UserContext);

  const checkExpiration = useCallback(() => {
    const userData = getLocalStorageItem(userDataKey);
    const { expirationTime, currentTime } = getExpirationTime(userData);

    if (!expirationTime) {
      clearInterval(checkExpirationInterval.current);
      return;
    }

    const timeToExpire = expirationTime - currentTime;
    const maxExpireTime = sessionRefreshTime
      ? sessionRefreshTime * minute
      : 15 * minute;

    if (timeToExpire < maxExpireTime) {
      setExpirationTime(timeToExpire);
      clearInterval(checkExpirationInterval.current);
    }
  }, [sessionRefreshTime]);

  const refreshSession = useCallback(async () => {
    const userData = getLocalStorageItem(userDataKey);
    const { token: currentToken } = userData;
    const { token, tokenExpirationDate } = await getNewTokenData(currentToken);

    refreshContextToken(token, tokenExpirationDate);
    storeToken(token, tokenExpirationDate);
  }, [refreshContextToken]);

  const closeSession = useCallback(() => {
    const userData = getLocalStorageItem(userDataKey);
    setLocalStorageItem(userDataKey, {
      ...userData,
      sessionExpired: true,
    });
    signOut();
  }, [signOut]);

  const onToastClick = useCallback(async () => {
    clearInterval(toastUpdateInterval.current);
    await refreshSession();
    setExpirationTime(null);
  }, [refreshSession]);

  const onTimeEnd = useCallback(() => {
    clearInterval(toastUpdateInterval.current);
    closeSession();
    window.location.reload();
  }, [closeSession]);

  const renderToast = useCallback(() => {
    setTimeout(() => {
      toast.info(
        () => (
          <ExpirationInfoToast
            time={Math.round(expirationTime / second)}
            onTimeEnd={onTimeEnd}
            intervalRef={toastUpdateInterval}
          />
        ),
        {
          autoClose: expirationTime,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          toastId: expirationToastId,
          onClick: onToastClick,
        },
      );
    }, 1000);
  }, [expirationTime, onTimeEnd, onToastClick]);

  useEffect(() => {
    const checkIntervalTime = checkSessionIntervalTime
      ? checkSessionIntervalTime * minute
      : 5 * minute;

    if (!expirationTime) {
      checkExpiration();
      checkExpirationInterval.current = setInterval(
        checkExpiration,
        checkIntervalTime,
      );
    }

    if (expirationTime) {
      document.addEventListener(
        "click",
        async () => {
          await refreshSession();
          toast.dismiss(expirationToastId);
          setExpirationTime(null);
        },
        { once: true, capture: true },
      );

      const isActive = toast.isActive(expirationToastId);
      if (isActive) {
        toast.dismiss(expirationToastId);
        renderToast();
      } else {
        renderToast();
      }
    }

    return () => clearInterval(checkExpirationInterval.current);
  }, [
    checkExpiration,
    checkSessionIntervalTime,
    expirationTime,
    onTimeEnd,
    refreshSession,
    renderToast,
  ]);
};

const ExpirationInfoToast = ({ time, onTimeEnd, intervalRef }) => {
  const [timer, setTimer] = useState(time);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, second);
  }, [intervalRef]);

  useEffect(() => {
    if (timer === 0) {
      setTimeout(() => {
        onTimeEnd();
      }, 1000);
    }
  }, [timer, onTimeEnd]);

  const getTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Text>Session is about to expire.</Text>
      <Text>Perform an action or click here to refresh session.</Text>
      <Text>Time left: {getTime(timer)}</Text>
    </Box>
  );
};

ExpirationInfoToast.propTypes = {
  time: PropTypes.number,
  onTimeEnd: PropTypes.func,
  intervalRef: PropTypes.object,
};
