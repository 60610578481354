import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { StylePropertiesTable } from "../cell-components/style-properties-table";
import { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export const StyleEditorValueCell = ({ onChange, propValue }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [styles, setStyles] = useState(propValue ?? {});
  const handleChange = (item) => setStyles((prev) => ({ ...prev, ...item }));

  const restore = () => setStyles(propValue);
  const handleCancel = () => {
    restore();
    onClose();
  };

  const handleSave = () => {
    onChange(styles);
    onClose();
    toast.success("Saved successfully.");
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button variant="ghost" onClick={onOpen}>
        ...
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxHeight="70vh" padding="10px">
            <ModalCloseButton />
            <ModalHeader>Style editor</ModalHeader>
            <ModalBody overflowY="auto">
              <StylePropertiesTable
                model={styles}
                onModelChange={handleChange}
              />
            </ModalBody>
            <ModalFooter gap="20px">
              <Button onClick={handleCancel} variant="ghost">
                Cancel
              </Button>
              <Button onClick={handleSave} colorScheme="blue">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

StyleEditorValueCell.propTypes = {
  onChange: PropTypes.func,
  propValue: PropTypes.object,
};
