import PropTypes from "prop-types";
import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { FiEdit, FiLink, FiLink2 } from "react-icons/fi";
import { getBlockByType } from "./blocks/utils/get-by-type";

const BlockComponent = ({
  data,
  highlightLink,
  onLinkClick,
  editModalComponent,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      className="disable-ppz-pan"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {data.displayName && (
        <Box
          className="disable-ppz-pan"
          lineHeight="10px"
          width="70px"
          whiteSpace="nowrap"
          textAlign="center"
          overflow="hidden"
          textOverflow="ellipsis"
          marginBottom="4px"
          fontSize="10px"
        >
          {data.displayName}
        </Box>
      )}
      <Box
        className="disable-ppz-pan"
        lineHeight="10px"
        width="70px"
        whiteSpace="nowrap"
        textAlign="center"
        overflow="hidden"
        textOverflow="ellipsis"
        marginBottom="4px"
        fontSize="10px"
      >
        ({data.name})
      </Box>

      <Box position={"relative"}>
        <IconButton
          className="disable-ppz-pan disable-ppz-drag"
          position="absolute"
          right="20px"
          top="2px"
          size="16px"
          variant="ghost"
        >
          <FiLink className="disable-ppz-pan disable-ppz-drag" size="16px" />
        </IconButton>
        <IconButton
          onClick={onOpen}
          className="disable-ppz-pan disable-ppz-drag"
          position="absolute"
          right="2px"
          top="2px"
          size="16px"
          variant="ghost"
        >
          <FiEdit className="disable-ppz-pan disable-ppz-drag" size="16px" />
        </IconButton>
        {getBlockByType(data.executeType)}
      </Box>
      <IconButton
        className="disable-ppz-pan disable-ppz-drag"
        variant="ghost"
        color={highlightLink ? "blue.500" : "black"}
        size="16px"
        onClick={onLinkClick}
      >
        <FiLink2 className="disable-ppz-pan disable-ppz-drag" size="16px" />
      </IconButton>
      {isOpen && editModalComponent({ isOpen, onOpen, onClose })}
    </Box>
  );
};

BlockComponent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    executeType: PropTypes.number.isRequired,
  }).isRequired,
  onLinkClick: PropTypes.func,
  highlightLink: PropTypes.bool,
  editModalComponent: PropTypes.func.isRequired,
};

export default BlockComponent;
