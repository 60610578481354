import { DatePicker } from "@echo/ui";
import React from "react";
import { getTextColor } from "../../../utils/theme-utils";
import { useDatagridContext } from "../../../hooks/useDatagridContext";

export const InputDateEditCell = (props) => {
  const { theme } = useDatagridContext();
  const { value, onChange, autoFocus, dateFormat } = props;
  return (
    <DatePicker
      date={value}
      onChange={onChange}
      autoFocus={autoFocus}
      format={dateFormat || "dd-MM-yyyy"}
      style={{ color: getTextColor(theme) }}
    />
  );
};
