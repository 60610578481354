// @flow
import React, { useState, useMemo, useRef, useEffect } from "react";
import { IconButton, Tr, useOutsideClick } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import type { Column, Record } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { CustomCheckbox } from "../checkbox/checkbox";
import { MdCheck } from "react-icons/md";
import { EditCell } from "../cell/edit-cell";
import { DisplayCell } from "../cell/display-cell";
import { getRecordValues, getResultData } from "../../utils/row-utils";
import { DATAGRID_BODY_ROW_CELL_TESTID } from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { getSelectedRowBackground } from "../../utils/theme-utils";
import { useProcessedValues } from "../../hooks/use-processed-values";

type RowProps = {
  record: Record,
  selectionMode?: "multiple",
  style: React.CSSProperties,
  onRowClick: () => void,
  onRowDoubleClick: () => void,
  onRowSelect: (e: any) => void,
};

export const Row = (props: RowProps): React.Node => {
  const { record, style, onRowClick, onRowDoubleClick, onRowSelect, columns } =
    props;
  const {
    columns: defaultColumns,
    selectionMode,
    selectedItems,
    actions: { onRowEdit },
    enableEdit,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
  } = useDatagridContext();

  const [editMode, setEditMode] = useState(false);
  const [focused, setFocused] = useState();
  const [rowState, setRowState] = useState(record);

  const values = useMemo(
    () => getRecordValues(record, columns),
    [record, columns],
  );

  const isChecked = (record, selectedItems) =>
    selectedItems?.find((item) => item == getId(record)) ? true : false;

  const handleCellValue = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => ({ ...prev, ...result }));
    }
  };

  const handleRowClick = (e) => {
    e.stopPropagation();
    if (editMode) return;
    if (!onRowClick) return;
    onRowClick();
  };

  const handleRowDoubleClick = (e) => {
    e.stopPropagation();
    if (editMode) return;
    if (!onRowDoubleClick) return;
    onRowDoubleClick();
  };

  const handleCellClick = (column: Column) => {
    if (!editMode && enableEdit && column.editable) {
      setFocused(column.order);
      setEditMode(true);
    }
  };

  const checkChanges = (oldState, newState) => {
    const keys = Object.keys(oldState);
    const boolArr = keys.map((k) => oldState[k] !== newState[k]);
    return boolArr.some((b) => b === true);
  };

  const handleRowEdit = () => {
    if (!onRowEdit || !enableEdit) return;
    const isChanged = checkChanges(record, rowState);
    if (isChanged) onRowEdit(rowState);
    setEditMode(false);
  };

  const rowRef = useRef();
  useOutsideClick({
    ref: rowRef,
    handler: () => {
      if (editMode && enableEdit) {
        handleRowEdit();
      }
    },
  });

  const getBackground = (record, selectedItems, style) => {
    const checked = isChecked(record, selectedItems);
    return getSelectedRowBackground(checked, style?.background);
  };

  const isEditable = (column, editMode) => {
    const { type, editType, editable } = column;

    if ((editMode || type === "boolean" || editType === "checkbox") && editable)
      return true;

    return false;
  };

  const { processedValues } = useProcessedValues(values, rowState);

  return (
    <Tr
      ref={rowRef}
      onClick={handleRowClick}
      onDoubleClick={handleRowDoubleClick}
      style={{
        ...style,
        borderBottom: "1px solid black",
        background: getBackground(record, selectedItems, style),
      }}
      background={getBackground(record, selectedItems, style)}
    >
      {(selectionMode === "single" || selectionMode === "multiple") && (
        <Cell
          column={defaultColumns.find((col) => col.name === "checkbox")}
          width={defaultColumns.find((col) => col.name === "checkbox").width}
          key={"checkbox-body"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px 8px",
          }}
          data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          {editMode ? (
            <IconButton
              icon={<MdCheck />}
              onClick={handleRowEdit}
              variant="outline"
            />
          ) : (
            <CustomCheckbox
              isChecked={isChecked(record, selectedItems)}
              onChange={onRowSelect}
              value={getId(record)}
            />
          )}
        </Cell>
      )}

      {rowState &&
        processedValues.length > 0 &&
        processedValues.map((val) => {
          const editable = isEditable(val.column, editMode);
          return editable ? (
            <EditCell
              column={val.column}
              value={rowState[val.column.name]}
              row={rowState}
              onChange={
                val.column.editType === "checkbox"
                  ? async (value) => {
                      await handleCellValue(value, val.column);
                      onRowEdit({ ...rowState, [val.column.name]: value });
                    }
                  : handleCellValue
              }
              autoFocus={val.column.order === focused}
              key={`${val.column.name}-${record.id}-${rowState?.[val?.column?.name]?.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{ whiteSpace: cellWhiteSpace }}
            />
          ) : (
            <DisplayCell
              column={val.column}
              value={rowState[val.column.name]}
              onClick={handleCellClick}
              row={rowState}
              key={`${val.column.name}-${record.id}-${rowState?.[val?.column?.name]?.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{
                whiteSpace: cellWhiteSpace || "nowrap",
                fontSize: rowFontSize,
                padding: "0 5px",
              }}
              cellLinesNumber={cellLinesNumber}
            />
          );
        })}
    </Tr>
  );
};
