/**
 *
 * @param {string} key key for value stored in localStorage
 * @param {any} value JavaScript value that will be stored in localStorage
 * @returns void
 */
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

/**
 *
 * @param {string} key
 * @returns JSON parsed to JavaScript value
 */
export const getLocalStorageItem = (key) => {
  // added try catch, there was a case where value couldn't be parsed cuz it wasn't a json.
  try {
    const value = JSON.parse(localStorage.getItem(key));
    return value;
  } catch (e) {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      throw new Error(e);
    }
  }
};

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();

/**
 *
 * @param {string[]} arr Array of keys that should not be cleared
 */
export const clearLocalStorageWithException = async (arr) => {
  const values = [];

  await arr.forEach((k) => {
    const item = localStorage.getItem(k);
    values.push({ key: k, value: item });
  });

  await localStorage.clear();

  await values.forEach(({ key, value }) => {
    localStorage.setItem(key, value);
  });
};
