import { useColorMode, useDisclosure } from "@chakra-ui/react";
import { useSelection } from "../../datagrid-v2-component/hooks/use-selection";
import { useDatagrid } from "../../datagrid-v2-component/hooks/use-datagrid-v2";
import { useContextActions } from "../../datagrid-v2-component/hooks/use-context-actions";
import { useTableActions } from "../../datagrid-v2-component/hooks/use-table-actions";
import { useEffect, useMemo, useRef } from "react";

export const useCombogrid = (props) => {
  const { selectionMode, rootProps, valueColumnName, onChange } = props;
  const { colorMode } = useColorMode();
  const { state, api, options } = useDatagrid(props);
  const { onColumnChange, refreshData } = api;

  const handleChange = (list) => {
    const json = JSON.stringify(list);
    onChange(json);
  };

  const { selectedItems, ...selectionFunctions } = useSelection(
    state.data,
    handleChange,
    selectionMode,
  );

  const {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    onOpen: onColumnsManagerOpen,
  } = useDisclosure();

  const {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    onOpen: onColumnsSettingsOpen,
  } = useDisclosure();

  const { contextActions, selectionContextActions } = useContextActions(
    { ...state, selectedItems },
    {
      ...api,
      ...selectionFunctions,
      onColumnsSettingsOpen,
      onColumnsManagerOpen,
    },
  );

  const onRowClick = (row) => {
    if (selectionMode === "multiple") return;
    const idKey = Object.keys(row).find((k) => k.toLowerCase() === "id");
    const id = row[idKey].toString();
    if (selectedItems.includes(id)) {
      selectionFunctions.selectSingle(id, "unselect");
    } else {
      selectionFunctions.selectSingle(id, "select");
    }
  };

  const tableActions = useTableActions({
    ...api,
    ...selectionFunctions,
    onRowClick,
    onRowDoubleClick: () => {},
  });

  const { nodeRef, ...restRootProps } = rootProps;

  const { isOpen } = useDisclosure();
  const popoverRef = useRef();

  const handleScrollToElement = (element) => {
    const rect = element.getBoundingClientRect();
    const elementBottomBorder = rect.bottom;
    const windowHeight = window.innerHeight;
    const isInView = elementBottomBorder < windowHeight;

    if (!isInView) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    if (isOpen && popoverRef.current) {
      handleScrollToElement(popoverRef.current);
    }
  }, [isOpen]);

  const inputValue = useMemo(() => {
    const filtered = state.data.filter((i) => {
      const idKey = Object.keys(i).find((k) => k.toLowerCase() === "id");
      const id = i[idKey].toString();
      return selectedItems.includes(id);
    });
    const mapped = filtered.map((i) => i[valueColumnName]);
    return (mapped || []).join(", ");
  }, [selectedItems, valueColumnName, state.data]);

  const inputProps = {
    label: props.label,
    value: inputValue,
    onChange: () => {},
    nodeRef,
    isReadOnly: true,
    ...restRootProps,
  };

  const datagridProps = {
    ...state,
    selectionMode: selectionMode === "multiple" ? selectionMode : "none",
    actions: tableActions,
    contextActions,
    selectionContextActions,
    enableAdding: options.addingNewRow,
    sort: props.disableSorting ? [] : state.settings.sorting,
    selectedItems,
    disableFilters: props.hideFilterRow || state.settings.disableFilters,
    disableHeader: props.hideHeaderColumns || state.settings.disableHeader,
    disableFooter: props.hideStatusRow,
    disableSummary: props.hideFooter,
    navPosition: state.settings.navPosition,
    summaryOptions: {},
    summaryValues: [],
    theme: colorMode,
  };

  const columnVisibilityProps = {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    columns: state.settings.columns,
    onChange: tableActions.onColumnVisibilityChange,
  };

  const columnSettingsProps = {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    defaultColumns: state.settings.columns,
    settingsId: state.settings.id,
    settingsKey: state.settings.key,
    onChange: (val, userId, roleId, type, company) => {
      onColumnChange(val, userId, roleId, type, company);
      refreshData();
    },
    sourceObject: state.source,
    isCombo: true,
    actions: [
      {
        name: "Reset",
        buttonProps: { colorScheme: "red" },
        action: tableActions.resetAction,
      },
    ],
    parent: props?.component,
  };

  return {
    inputProps,
    datagridProps,
    columnVisibilityProps,
    columnSettingsProps,
    popoverRef,
  };
};
