import PermissionEditorEditCell from "../../../../permission-editor/permission-editor-edit-cell";
import EchoBoolValueCell from "../../../properties-table/value-cell/echo-bool-value-cell";
import EchoComboValueCell from "../../../properties-table/value-cell/echo-combo-value-cell";
import { StyleEditorValueCell } from "../../../properties-table/value-cell/style-editor-value-cell";
import EchoInputStateValueCell from "../../echo-input-state-value-cell";
import EchoStateValueCell from "../../echo-state-value-cell";

export const inputTypes = [
  {
    name: "processSelect",
    component: ({ propValue, onChange, propName, disabled }) => (
      <EchoStateValueCell
        propName={propName}
        propValue={propValue}
        onChange={onChange}
        disabled={disabled}
      />
    ),
  },
  {
    name: "inputSelect",
    component: ({ propValue, onChange, propName, disabled }) => (
      <EchoInputStateValueCell
        propName={propName}
        propValue={propValue}
        onChange={onChange}
        disabled={disabled}
      />
    ),
  },
  {
    name: "boolSelect",
    component: ({ propName, propValue, onChange }) => (
      <EchoBoolValueCell
        propName={propName}
        propValue={propValue}
        onChange={onChange}
      />
    ),
  },
  {
    name: "comboSelect",
    component: ({ propValue, onChange, options, propType }) => {
      return (
        <EchoComboValueCell
          propValue={propValue}
          propType={propType}
          onChange={(selection) => onChange(selection || null)}
          options={options}
          enableProcessSelect
        />
      );
    },
  },
  {
    name: "permissionEditorPropSelect",
    component: ({ propValue, onChange, model }) => (
      <PermissionEditorEditCell
        propValue={propValue}
        propTypes={[{ propName: "isVisible" }]}
        onChange={onChange}
        model={model}
      />
    ),
  },
  {
    name: "styleEditor",
    component: ({ propValue, onChange, model }) => (
      <StyleEditorValueCell
        propValue={propValue}
        onChange={onChange}
        model={model}
      />
    ),
  },
];
