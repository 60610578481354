import { useCallback, useEffect, useRef, useState } from "react";

export const useMenuResize = (width, setWidth) => {
  const [resizing, setResizing] = useState(false);

  const mousePos = useRef();
  const menuRef = useRef();

  const onWidthChange = useCallback(
    (diff) => {
      if (!menuRef.current) return;

      const newWidth = width + diff;
      menuRef.current.style.width = `${newWidth}px`;
    },
    [menuRef, width],
  );

  const onMouseUp = (e) => {
    const start = mousePos.current;
    const current = e.clientX;
    const dx = current - start;
    setResizing(false);
    setWidth((prev) => prev + dx);
  };

  const onMouseDown = (e) => {
    mousePos.current = e.clientX;
    setResizing(true);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      const start = mousePos.current;
      const current = e.clientX;
      const dx = current - start;
      onWidthChange(dx);
    };

    if (resizing) {
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, [resizing, onWidthChange]);

  return { menuRef, resizing, onMouseUp, onMouseDown };
};
