import { Quill } from "react-quill";

const Clipboard = Quill.import("modules/clipboard");

export class CustomClipboard extends Clipboard {
  onPaste(e) {
    const html = (e.clipboardData || window.clipboardData).getData("text/html");

    if (html) {
      e.preventDefault();

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const paragraphs = doc.querySelectorAll("p");
      paragraphs.forEach((p) => {
        if (p.hasAttribute("id")) {
          p.setAttribute("data-id", p.getAttribute("id"));
        }
      });

      const sanitizedHTML = doc.body.innerHTML;

      const delta = this.quill.clipboard.convert(sanitizedHTML);

      const ops = delta.ops.map((op) => {
        if (op.attributes && op.attributes["data-id"]) {
          op.attributes.id = op.attributes["data-id"];
          delete op.attributes["data-id"];
        }
        return op;
      });

      this.quill.updateContents({ ...delta, ops }, "user");
    } else {
      super.onPaste(e);
    }
  }
}
