export const localStorageKeys = {
  BLOCKS: "blocks",
  USER_DATA: "user_data",
  OPENED_PAGES: "opened_pages",
  VERSION: "version",
  GOOGLE_AUTH_INFO: "google_auth_info",
  GOOGLE_AUTH_STATUS: "google_auth_status",
  APP_CONFIG: "app_config",
  CHAKRA_THEME: "chakra-ui-color-mode",
};
