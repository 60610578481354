import { useEffect, useRef } from "react";

export const useComponentUnmount = (callback) => {
  const funcRef = useRef();

  useEffect(() => {
    funcRef.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      funcRef.current();
    };
  }, []);
};
