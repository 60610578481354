import { eachDayOfInterval, endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { useMemo, useState } from "react";

export const useCalendarUtils = () => {
  const currentDate = new Date();
  const [weekStart, setWeekStart] = useState(
    startOfWeek(currentDate, { weekStartsOn: 1 }),
  );
  const weekEnd = useMemo(
    () => endOfWeek(weekStart, { weekStartsOn: 1 }),
    [weekStart],
  );
  const weekDays = useMemo(
    () => eachDayOfInterval({ start: weekStart, end: weekEnd }),
    [weekStart, weekEnd],
  );

  const nextWeek = () => setWeekStart((prev) => subWeeks(prev, -1));
  const prevWeek = () => setWeekStart((prev) => subWeeks(prev, 1));

  return { weekDays, weekStart, weekEnd, nextWeek, prevWeek };
};
