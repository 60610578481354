import React, { useCallback, useMemo, useRef } from "react";
import { Table, TableContainer, Box } from "@chakra-ui/react";
import { Header } from "./header/header";
import { Footer } from "./footer/footer";
import { Navigation } from "./navigation/navigation";
import { Body } from "./body/body";
import { useDatagridContext } from "../hooks/useDatagridContext";
import { FilterRow } from "./header/filter-row";
import { DATAGRID_CONTAINER_TESTID } from "../utils/test-ids";
import { useDragContext } from "../hooks/useDragContext";

export const DatagridSimple = () => {
  const {
    loading,
    disableFilters,
    settings,
    disableHeader,
    disableFooter,
    disableSummary,
    visibleColumns,
    navPosition,
  } = useDatagridContext();

  const { containerRef } = useDragContext();

  const defaultColumnWidth = useMemo(() => {
    if (!containerRef.current) return "150px";
    const width = containerRef.current.scrollWidth;
    const count = visibleColumns.length;
    const resWidthInt = Math.floor(width / count);
    if (resWidthInt < 150) return "150px";
    return `${resWidthInt}px`;
  }, [containerRef, visibleColumns]);

  const columnMapper = useCallback(
    (c) => {
      if (c.name === "checkbox") return c;
      if (c.width) return c;
      return { ...c, width: defaultColumnWidth };
    },
    [defaultColumnWidth],
  );

  const columns = useMemo(
    () => visibleColumns.map(columnMapper),
    [visibleColumns, columnMapper],
  );

  return (
    <Box
      overflow="hidden"
      height="100%"
      width="100%"
      maxWidth="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid={DATAGRID_CONTAINER_TESTID}
    >
      {!disableFooter && navPosition === "top" && <Navigation />}
      <TableContainer
        height="100%"
        width="100%"
        border="1px solid var(--chakra-colors-gray-200)"
      >
        <Table
          overflow="auto"
          size="sm"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          variant="unstyled"
          width="100%"
          position="relative"
          zIndex={2}
          ref={containerRef}
        >
          {!disableHeader && <Header columns={columns} />}
          {!loading ? (
            <Body columns={columns} />
          ) : (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              Loading data...
            </Box>
          )}
          {!disableSummary && <Footer columns={columns} />}
        </Table>
      </TableContainer>
      {!disableFooter && navPosition !== "top" && <Navigation />}
    </Box>
  );
};
