import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, IconButton, Input, Tooltip } from "@chakra-ui/react";
import { useShowTooltip } from "@echo/ui";

const Tab = ({
  text,
  isActive,
  onClick,
  enableClose,
  onClose,
  editMode,
  onEdit,
  draggable,
  onDragStart,
  onDragEnd,
  onDrag,
  label,
  minWidth,
  maxWidth,
  onMouseDown,
}) => {
  const [editState, setEditState] = useState("");

  const handleOnClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose(e);
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit(editState);
    }
  };

  useEffect(() => {
    setEditState(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const contentRef = useRef();
  const { showTooltip } = useShowTooltip(contentRef.current);

  return (
    <Tooltip
      padding="2px 4px"
      isDisabled={!showTooltip}
      label={label || text}
      placement="top"
      openDelay={1000}
    >
      <Box
        className={`pointer-hand-hover ${isActive ? "active-tab" : ""}`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="center"
        width="auto"
        boxSizing="border-box"
        borderRight="1px solid var(--chakra-colors-lightGrayCa)"
        fontWeight="300"
        alignItems="center"
        whiteSpace="nowrap"
        gap="5px"
        padding="5px 3px 5px 5px"
        minWidth={minWidth}
        maxWidth={maxWidth}
      >
        {editMode ? (
          <Input
            value={editState}
            onChange={(e) => setEditState(e.target.value)}
            variant="unstyled"
            onBlur={handleEdit}
          />
        ) : (
          <span
            ref={contentRef}
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </span>
        )}
        {enableClose ? (
          <IconButton
            variant="ghost"
            size={isActive ? "small" : "x-small"}
            style={{
              background: "transparent",
              size: "5px",
            }}
            onClick={handleOnClose}
          >
            <CloseIcon
              fontSize={isActive ? "small" : "x-small"}
              size={isActive ? "small" : "x-small"}
            />
          </IconButton>
        ) : null}
      </Box>
    </Tooltip>
  );
};

Tab.propTypes = {
  text: PropTypes.string,
  editMode: PropTypes.bool,
  onEdit: PropTypes.func,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  enableClose: PropTypes.bool,
  onClose: PropTypes.func,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  label: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  onMouseDown: PropTypes.func,
};

export default Tab;
