import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { ItemsList } from "./items-list";
import { actionPropertyTypes } from "../utils/property-types";
import { inputTypes } from "../utils/input-types";
import { uuidv4 } from "@echo/tools/src";
import { useItemsList } from "./hooks/use-items-list";

export const DatagridActionsEditor = (props) => {
  const { propValue, designerPortalRef, onChange, model } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    activeItem,
    addItem,
    deleteItem,
    list,
    onItemClick,
    restoreList,
    saveList,
    updateItem,
    updateList,
  } = useItemsList(propValue, onChange);

  const handleCancel = () => {
    restoreList();
    onClose();
  };

  const handleSave = () => {
    saveList();
    onClose();
  };

  const addRowBtnName = "addRowBtn";
  const addRowBtnModel = useMemo(
    () => ({
      id: uuidv4(),
      name: addRowBtnName,
      text: "Add row",
      onClick: { name: "Default Event" },
      isSystem: true,
    }),
    [],
  );

  const generateElementId = (element) =>
    element.id ? element : { ...element, id: uuidv4() };

  const actionsWithIds = useMemo(
    () => propValue.map(generateElementId),
    [propValue],
  );

  const addRowBtnExists = useMemo(
    () => actionsWithIds.find((el) => el.name === addRowBtnName),
    [actionsWithIds],
  );

  const conditions = useMemo(
    () => [
      {
        value: list,
        condition: model.enableAdding && addRowBtnExists,
        func: (value) => updateList(value),
      },
      {
        value: list,
        condition: !model.enableAdding && !addRowBtnExists,
        func: (value) => updateList(value),
      },
      {
        value: [addRowBtnModel, ...actionsWithIds],
        condition: model.enableAdding && !addRowBtnExists,
        func: (value) => updateList(value),
        shouldSave: true,
      },
      {
        value: actionsWithIds.filter((a) => a.name !== addRowBtnName),
        condition: !model.enableAdding && addRowBtnExists,
        func: (value) => updateList(value),
        shouldSave: true,
      },
    ],
    [
      actionsWithIds,
      addRowBtnExists,
      addRowBtnModel,
      list,
      model.enableAdding,
      updateList,
    ],
  );

  useEffect(() => {
    conditions.forEach((c) => {
      if (!c.condition) return;
      c.func(c.value);

      if (c.shouldSave) onChange(c.value);
    });
  }, [conditions, onChange]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button onClick={onOpen} color="none" variant="ghost" size="md">
        {list && list.length.toString()}...
      </Button>
      <Modal
        portalProps={{ containerRef: designerPortalRef }}
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Datagrid actions</ModalHeader>
          <ModalBody>
            <ItemsList
              items={list.map((el) =>
                el.name === "addRowBtn" ? { ...el, disabled: true } : el,
              )}
              onItemChange={updateItem}
              deleteItem={deleteItem}
              onItemClick={onItemClick}
              propertyTypes={actionPropertyTypes}
              inputTypes={inputTypes}
              activeItem={activeItem}
              updateItem={updateItem}
            />
            <Button onClick={addItem} variant="ghost">
              Add new
            </Button>
          </ModalBody>
          <ModalFooter gap="20px">
            <Button onClick={handleCancel} variant="ghost">
              Cancel
            </Button>
            <Button onClick={handleSave} colorScheme="blue">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DatagridActionsEditor.propTypes = {
  onChange: PropTypes.func,
  propName: PropTypes.string,
  propType: PropTypes.string,
  propValue: PropTypes.string,
  designerPortalRef: PropTypes.any,
  model: PropTypes.shape({
    enableAdding: PropTypes.bool,
  }),
};
