import PropTypes from "prop-types";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import FlowSettingsTab from "../flow-settings-tab";
import EditorTab from "../editor-tab/editor-tab";
import ParamsSetting from "../../../../shared/params-setting/params-settings";
import { PermissionsTab } from "../permissions-tab/permissions-tab";

const BlockEditTabs = ({ model, updateModel }) => {
  return (
    <Tabs display="flex" flex="3 1 0" flexFlow="column" minHeight={0}>
      <TabList>
        <Tab>Input types</Tab>
        <Tab>Input parameters</Tab>
        <Tab>Return type</Tab>
        <Tab>Editor</Tab>
        <Tab>Permissions</Tab>
      </TabList>

      <TabPanels height="100%">
        <TabPanel height="100%">
          <FlowSettingsTab
            model={model}
            updateModel={updateModel}
            modelName="inParameters"
            editEnabled
          />
        </TabPanel>
        <TabPanel>
          <ParamsSetting
            onAction={() => {}}
            parametersSchema={model.inParameters}
            model={model.staticParams ?? {}}
            onUpdate={(newStaticParams) => {
              updateModel({ ...model, staticParams: newStaticParams });
            }}
          />
        </TabPanel>
        <TabPanel>
          <FlowSettingsTab
            model={model}
            updateModel={updateModel}
            editEnabled
            modelName="returnType"
          />
        </TabPanel>
        <TabPanel height="100%" minHeight={0}>
          <EditorTab model={model} updateModel={updateModel} />
        </TabPanel>
        <TabPanel>
          <PermissionsTab model={model} updateModel={updateModel} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

BlockEditTabs.propTypes = {
  model: PropTypes.any,
  updateModel: PropTypes.func.isRequired,
};

export default BlockEditTabs;
