const defaultColumns = [
  { name: "user_id", type: "number", format: "##0", editable: true },
  { name: "company_id", type: "number", format: "##0", editable: true },
  { name: "role_id", type: "number", format: "##0", editable: true },
];

const getColumnType = (val) => typeof val;

const getColumnVisibility = (colName) =>
  colName.toLowerCase() === "id" ? false : true;

const getColumnFormat = (colName, colType) =>
  colType === "number" && colName.toLowerCase().includes("id")
    ? "##0"
    : "##0.00";

export const getColumns = (data) => {
  if (!data) return defaultColumns;
  if (!data[0]) return defaultColumns;

  const keys = Object.keys(data[0]);
  return keys.map((k) => ({
    name: k,
    type: getColumnType(data[0][k]),
    isVisible: getColumnVisibility(k),
    format: getColumnFormat(k, getColumnType(data[0][k])),
    editable: true,
  }));
};
