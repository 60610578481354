import PropTypes from "prop-types";
import { Text, useColorMode } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import { useEffect, useState } from "react";
import {
  getBackground,
  getTextColor,
} from "@echo/datagrid/src/utils/theme-utils";

const TextComponent = ({
  fontSize,
  text = "Edit text in properties tab",
  rootProps,
}) => {
  const { colorMode } = useColorMode();
  const { nodeRef, ...restRootProps } = rootProps;
  const [textState, setTextState] = useState(" ");

  useEffect(() => {
    if (typeof text === "string") {
      setTextState(text);
    } else if (typeof text === "function") {
      Promise.resolve(text())
        .then((result) => {
          if (typeof result === "string") {
            setTextState(result);
          } else {
            const name = result.name;
            const toName = result.toName;
            const value = toName ? result[toName] : result[name];
            setTextState(value || "");
          }
        })
        .catch(() => {});
    }
  }, [text]);

  const getBackgroundColor = (style) => {
    if (!style || (style && !style.backgroundColor)) {
      return getBackground(colorMode);
    }

    return style.backgroundColor;
  };

  const getFontColor = (style) => {
    if (!style || (style && !style.color)) return getTextColor(colorMode);

    return style.color;
  };

  return (
    <Text
      ref={nodeRef}
      {...restRootProps}
      backgroundColor={getBackgroundColor(restRootProps?.style)}
      color={getFontColor(restRootProps?.style)}
      fontSize={fontSize}
    >
      {textState}
    </Text>
  );
};

TextComponent.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
  rootProps: PropTypes.any,
};

export default withBaseComponent(TextComponent);
