import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { uuidv4 } from "@echo/tools";
import EchoRouterContext from "../../../echo-router/echo-router-context";
import { FiTrash2 } from "react-icons/fi";
import { echoPortal } from "../../../echo-portal/echo-portal";
import QuestionAlert from "../../echo-components/base-components/components/question-alert-component/question-alert.component";

const EchoStateValueCell = ({
  propName,
  propValue = "",
  onChange,
  diagramParams,
  disabled,
}) => {
  const { openPage, focusPage, getActivePage } = useContext(EchoRouterContext);

  const [openedPageKey, setOpenedPageKey] = useState(null);

  const handleOnSave = (process) => {
    onChange({ type: "PROCESS", name: process.name, id: process.id });
  };

  const handleOnClick = () => {
    const prevPage = getActivePage().displayName.split("-")[0];
    if ((openedPageKey && !focusPage(openedPageKey)) || !openedPageKey) {
      setOpenedPageKey(
        openPage(
          propValue && propValue.id && propValue.type === "PROCESS"
            ? `diagramDesigner/${propValue.id}`
            : "diagramDesigner",
          {
            diagramParams,
            afterCreateEvent: {
              afterCreateEventName: "SAVE_CALLBACK",
              callbackEvent: handleOnSave,
              customParams: {
                name: `${propName}-${uuidv4()}`,
                description: `${propName} process.`,
                private: true,
              },
            },
          },
          `${prevPage} / ${propName}${propName ? " - " : ""}Diagram designer`,
          true,
        ),
      );
    }
  };

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlert
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: propValue ? "space-between" : "flex-end",
        flexDirection: "row",
        marginLeft: "8px",
      }}
    >
      <div
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {propValue?.name && `Process: ${propValue?.name}`}
      </div>
      <button
        onClick={handleOnClick}
        tabIndex={-1}
        disabled={disabled}
        style={{
          cursor: disabled ? "default" : "pointer",
        }}
      >
        ...
      </button>
      <button
        onClick={handleOnDelete}
        tabIndex={-1}
        disabled={disabled}
        style={{
          cursor: disabled ? "default" : "pointer",
        }}
      >
        <FiTrash2 size="16px" />{" "}
      </button>
    </div>
  );
};

EchoStateValueCell.propTypes = {
  propName: PropTypes.string,
  propValue: PropTypes.any,
  onChange: PropTypes.func,
  diagramParams: PropTypes.object,
  disabled: PropTypes.bool,
};

export default EchoStateValueCell;
