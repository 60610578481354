export const getBackground = (theme) => {
  switch (theme) {
    case "dark":
      return "#1e2129 !important";
    case "light":
    default:
      return "#ffffff !important";
  }
};

export const getTextColor = (theme) => {
  switch (theme) {
    case "dark":
      return "fff !important";
    case "light":
    default:
      return "#111 !important";
  }
};
