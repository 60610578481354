import { showErrorToast } from "../../../../../echo-error-toast";

const getValueFromResult = (res) => {
  if (!res) return null;
  if (typeof res !== "object") return res;
  if (res.value) return res.value;
  if (Array.isArray(res) && res[0]) {
    return getValueFromResult(res[0]);
  }

  return Object.values(res)[0];
};

const executeTabProcess = async (tab) => {
  const keys = Object.keys(tab);
  const newKeys = keys.map(async (k) => {
    if (typeof tab[k] === "function") {
      const result = await tab[k]();
      const value = getValueFromResult(result);
      return { key: k, value };
    }

    return { key: k, value: tab[k] };
  });

  const resolvedKeys = await Promise.all(newKeys);

  return resolvedKeys.reduce(
    (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
    {},
  );
};

export const resolveTabsProcess = async (tabs) => {
  const promises = tabs.map(async (t) => await executeTabProcess(t));
  const wrappedPromises = promises.map((promise) =>
    promise
      .then((value) => ({ status: "fulfilled", value }))
      .catch((error) => {
        showErrorToast({ ...error, location: "Tabs component" });
        return { status: "rejected", reason: error };
      }),
  );
  const result = await Promise.all(wrappedPromises);
  return result.filter((r) => r.status === "fulfilled").map((r) => r.value);
};
