// @flow
import { useEffect, useState } from "react";
import { type Column } from "../datagrid-v2";

type TValue = {
  column: Column,
  value: any,
};

type TState = {
  [key: string]: any,
};

export const useProcessedValues = (values: TValue[], state: TState) => {
  const [processedValues, setProcessedValues] = useState([]);

  const getIsEditable = async (editable, state) => {
    if (typeof editable === "function") {
      const res = await editable(state);
      return !!res;
    }

    return editable;
  };

  const mapValue = async (val, state) => {
    const { column } = val;
    const editable = await getIsEditable(column.editable, state);
    return { ...val, column: { ...val.column, editable } };
  };

  const processValues = async (values, state) => {
    const promises = values.map(async (val) => {
      const newVal = await mapValue(val, state);
      return newVal;
    });

    const result = await Promise.all(promises);
    return result;
  };

  useEffect(() => {
    processValues(values, state).then(setProcessedValues);
  }, [values, state]);

  return { processedValues };
};
