import PropTypes from "prop-types";
import { Box, IconButton } from "@chakra-ui/react";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import PropertiesShim from "../../../../../shared/shim-components/properties-shim/properties-shim";
import { getComponent } from "../../../../../../utils/echo-component/echo-component-utils";
import ComponentPropertiesTable from "../../../../../shared/properties/component-properties-table/component-properties-table";
import { ChevronRightIcon, ChevronUpIcon, DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { Input } from "@echo/ui";
import { getPropertyDefinitionById } from "../../../../../../services/component-property-definition-service";

const getTableOptionType = (propTypeName) => {
  switch (propTypeName) {
    case "string":
      return "inputSelect";
    case "bool":
    case "boolean":
      return "boolSelect";
    case "oneOf":
      return "comboSelect";
    case "datagridColumnsArrayOf":
      return "datagridColumnsEditor";
    case "datagridActionsArrayOf":
      return "datagridActionsEditor";
    case "relatedObjectArrayOf":
      return "relatedObjectIdSelect";
    case "tabsArrayOf":
      return "tabsPropSelect";
    case "permissionEditorArrayOf":
      return "permissionEditorPropSelect";
    // TODO Add support to standard arrayOf
    // return 'arrayOfSelect';
    case "listEventListenersArrayOf":
      return "listEventListenersPropSelect";
    case "arrayOf":
    default:
      return "processSelect";
  }
};

const getPropertiesTableOptions = (propTypesModel) => {
  switch (propTypesModel?.type) {
    case "built-in": {
      const propTypes = propTypesModel.properties;
      let result = Object.keys(propTypes).map((propKey) => {
        return {
          propName: propKey,
          propType: getTableOptionType(propTypes[propKey]?.info?.propTypeName),
          propTypeObject: propTypes[propKey],
        };
      });
      return result.some((o) => o.propName === "name")
        ? result
        : [{ propName: "name", propType: "input" }, ...result];
    }
    case "!?echo-defined":
      return [
        { propName: "name", propType: "input" },
        { propName: "useRootContext", propType: "bool" },
        ...(propTypesModel?.properties || []).map(({ propName, propType }) => ({
          propName,
          propType: getTableOptionType(propType),
        })),
      ];
    default:
      return [];
  }
};

const getPropTypes = (component) => {
  const source = component?.component?.source;

  if (!source) {
    return null;
  }

  if (source === "!?echo-defined") {
    return {
      type: "!?echo-defined",
      properties: component.component.properties,
    };
  } else {
    return {
      type: "built-in",
      properties: getComponent(source).propTypes,
    };
  }
};

const sortFn = (a, b) => a.order - b.order;
const propertyMapper = (p) =>
  p.options
    ? {
        propName: p.name,
        propType: getTableOptionType(p.type),
        propTypeObject: {
          info: {
            allowedValues: p.options.split(","),
          },
        },
      }
    : {
        propName: p.name,
        propType: getTableOptionType(p.type),
      };

const Properties = ({
  modelSchema,
  component,
  onModelSchemaChange,
  onAction,
  designerPortalRef,
  componentInfo,
  id = null,
  onModelChange = shimFunctions.shimFunction1,
  model = {},
}) => {
  const [componentId, setComponentId] = useState(component?.id);
  const [properties, setProperties] = useState([]);

  const handleProperties = async (component) => {
    if (!component) return;
    if (!component.component) return;
    if (!component.component.id) return;

    const propTypes = getPropTypes(component);
    const options = getPropertiesTableOptions(propTypes);

    const id = component.component.id;
    const propsDefinition = await getPropertyDefinitionById(id);
    const mapped = propsDefinition.sort(sortFn).map(propertyMapper);

    const properties = [
      ...mapped,
      ...options.filter(
        (opt) => !mapped.find((p) => opt.propName === p.propName),
      ),
    ].filter((p) => p.propName);

    setProperties(properties);
  };

  useEffect(() => {
    if (component) {
      handleProperties(component);
    }
  }, [component]);

  return id && model !== null && component ? (
    <div style={{ padding: "16px", height: "100%", overflowY: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {"\t"}
          {component?.component?.displayName},{"\t"}name:{" "}
          {component?.componentProps?.name
            ? component?.componentProps?.name
            : "<unknown_name>"}
          , <br />
          {"\t"}
          <br />
          id: {component.id > 0 ? component.id : "<new>"}
        </div>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Input
              // style={{ backgroundColor: "white" }}
              value={componentId}
              onChange={(e) => setComponentId(e.target.value)}
            />
            <IconButton
              color="secondary"
              variant="contained"
              onClick={() =>
                onAction(id, { type: "GO_TO", id: Number(componentId) })
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <IconButton
            color="secondary"
            variant="contained"
            onClick={() => onAction(id, { type: "GO_TO_PARENT" })}
          >
            <ChevronUpIcon />
          </IconButton>

          <IconButton
            onClick={() => onAction(id, { type: "DELETE" })}
            color="secondary"
            variant="contained"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </div>
      {properties.length > 0 ? (
        <ComponentPropertiesTable
          key={component.id}
          options={properties}
          stateSchema={modelSchema}
          onStateSchemaChange={onModelSchemaChange}
          model={model}
          onModelChange={onModelChange}
          designerPortalRef={designerPortalRef}
          componentId={component?.id}
          diagramParams={{ objectId: componentInfo.relatedObjectId }}
        />
      ) : null}
    </div>
  ) : (
    <PropertiesShim />
  );
};

Properties.propTypes = {
  id: PropTypes.any,
  model: PropTypes.object,
  modelSchema: PropTypes.object.isRequired,
  onModelSchemaChange: PropTypes.func.isRequired,
  onAction: PropTypes.funcOf(PropTypes.number),
  onModelChange: PropTypes.funcOf(PropTypes.object),
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  component: PropTypes.shape({
    id: PropTypes.number,
    depth: PropTypes.number,
    componentProps: PropTypes.any, // todo replace any
    source: PropTypes.string,
    component: PropTypes.shape({
      id: PropTypes.number,
      source: PropTypes.string.isRequired,
      displayName: PropTypes.string,
    }),
  }),
  componentInfo: PropTypes.shape({ relatedObjectId: PropTypes.any }),
};

export default Properties;
