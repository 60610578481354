import { uuidv4 } from "@echo/tools";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

const setIds = (arr) => arr.map((el) => (el.id ? el : { id: uuidv4() }));

export const useItemsList = (items, changeFn) => {
  const [list, setList] = useState(() => setIds(items));
  const [activeItem, setActiveItem] = useState();

  const onItemClick = (i) => setActiveItem(i);

  const updateList = (list) => setList(list);

  const addItem = () => {
    const newAction = { id: uuidv4() };
    setActiveItem(newAction);
    setList((prev) => [...prev, newAction]);
  };

  const updateItem = (action) => {
    setActiveItem(action);
    setList((prev) => prev.map((el) => (el.id === action.id ? action : el)));
  };

  const deleteItem = useCallback(
    (action) => {
      if (activeItem && action.id === activeItem.id) {
        setActiveItem();
      }

      setList((prev) => prev.filter((el) => el.id !== action.id));
    },
    [activeItem],
  );

  const restoreList = () => {
    setActiveItem();
    setList(items);
  };

  const saveList = useCallback(() => {
    setActiveItem();
    changeFn(list);
    toast.success("Saved successfully.");
  }, [list, changeFn]);

  return {
    activeItem,
    list,
    addItem,
    updateItem,
    deleteItem,
    restoreList,
    saveList,
    onItemClick,
    updateList,
  };
};
